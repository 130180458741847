import React, { forwardRef, ForwardRefRenderFunction } from 'react';

// Custom styles for MDBox
import MDBoxRoot from './MDBoxRoot';
import { validColors } from '../../assets/theme/base/colors';

interface MDBoxPropTypes {
  variant?: 'contained' | 'gradient';
  bgColor?: typeof validColors[number];
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  coloredShadow?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'none';
  [x: string]: any;
}

const MDBox: ForwardRefRenderFunction<any, MDBoxPropTypes> = (
  {
    variant = 'contained',
    bgColor = 'transparent',
    color = 'dark',
    opacity = 1,
    borderRadius = 'none',
    shadow = 'none',
    coloredShadow = 'none',
    ...rest
  },
  ref,
) => {
  return (
    <MDBoxRoot
      ref={ref}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
        coloredShadow,
      }}
      {...rest}
    />
  );
};

export default forwardRef<any, MDBoxPropTypes>(MDBox);
