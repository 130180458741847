import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface IModalProps {
  title: string;
  children: React.ReactNode;
  size?: 'S' | 'M' | 'L';
  open: boolean;
  onClose: () => void;
}

const getSize = (size: 'S' | 'M' | 'L') => {
  switch (size) {
    case 'S':
      return { width: 500, height: '50%' };
    case 'M':
      return { width: 900, height: 600 };
    case 'L':
      return { width: 1200, height: 800 };
    default:
      return { width: 1200, height: 800 };
  }
};

export default function MDModal({ title, children, size = 'S', open, onClose }: IModalProps) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    overflowY: 'scroll',
    overflow: 'auto',
    ...getSize(size),
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid container alignItems={'center'}>
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid ml="auto" item>
              <IconButton aria-label="close" onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {children}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
