import React, { useCallback, useState } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import Card from '@mui/material/Card';

const DragDropFileUpload = ({ onFileUpload }: any) => {
  const [dragOver, setDragOver] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((event) => {
    event.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      setDragOver(false);
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        const arr = [];
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          arr.push(event.dataTransfer.files[i]);
        }
        onFileUpload(arr);
        setFiles(arr);
        console.log(arr);
      }
    },
    [onFileUpload],
  );

  const handleChange = useCallback(
    (event) => {
      if (event.target.files && event.target.files[0]) {
        const arr = [];
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          arr.push(event.dataTransfer.files[i]);
        }
        onFileUpload(arr);
        setFiles(arr);
      }
    },
    [onFileUpload],
  );
  return (
    <Paper
      variant="outlined"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{
        border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
        padding: 50,
        textAlign: 'center',
        cursor: 'pointer',
        background: dragOver ? '#eee' : '#fafafa',
      }}>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="raised-button-file">
        {files.length === 0 ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton color="info" aria-label="upload picture" component="span">
              <CloudUploadIcon style={{ width: 60, height: 60 }} />
            </IconButton>
            <Typography>Drag and drop files here or click to select files</Typography>
          </Box>
        ) : (
          files.map((e, i) => (
            <Card key={i} sx={{ height: '100%', boxShadow: 'none' }}>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  {e.name}
                </MDTypography>
              </MDBox>
            </Card>
          ))
        )}
      </label>
    </Paper>
  );
};

export default DragDropFileUpload;
