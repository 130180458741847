/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDBadge from '../../../components/MDBadge';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import React, { useCallback, useEffect, useState } from 'react';

import MDButton from '../../../components/MDButton';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SPONSOR_REQUEST, UPDATE_SPONSOR_REQUEST } from '../../../queries/sponsorRequest';

type DataTablePagination = { page: number; size: number };

export default function data() {
  const MySwal = withReactContent(Swal);
  const [dataItems, setDataItems] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<DataTablePagination>({ page: 0, size: 10 });
  const [query, setQuery] = useState<any>({});
  const { refetch } = useQuery(GET_SPONSOR_REQUEST, {
    variables: {
      skip: 0,
      take: pagination.size,
    },
  });
  const [acceptQ] = useMutation(UPDATE_SPONSOR_REQUEST);
  const [rejectQ] = useMutation(UPDATE_SPONSOR_REQUEST);

  const search = useCallback(
    (query: any, pagination: any) => {
      setLoading(true);
      refetch({
        skip: pagination.page * pagination.size,
        take: pagination.size,
        query: { status: 'PENDING', ...query },
      })
        .then((value) => {
          setDataItems(value.data?.sponsorships.data);
          setTotal(value.data?.sponsorships.count ?? 0);
        })
        .finally(() => setLoading(false));
    },
    [refetch],
  );

  const acceptSponsorRequests = useCallback(
    async (id: string) => {
      await MySwal.fire({
        // titleText: 'Accept Sponsor Request',
        title: 'Are you sure?',
        text: 'Do You want Accept this SponsorRequests!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4250ce',
        cancelButtonColor: '#dc3545',
        confirmButtonText: 'Yes, Accept it!',
        preConfirm: async () => {
          MySwal.fire({
            title: 'Processing!',
            didOpen: () => {
              MySwal.showLoading();
            },
            allowOutsideClick: () => !MySwal.isLoading(),
          }).then(async () => {
            console.log('');
          });
          try {
            acceptQ({
              variables: {
                updateSponsorship: {
                  status: 'ACCEPTED',
                  sponsorshipId: id,
                },
              },
            }).then(async () => {
              MySwal.hideLoading();
              search(query, pagination);
              await MySwal.fire(
                'Success!',
                'SponsorRequests details accept successfully ',
                'success',
              );
            });
          } catch (e: any) {
            if (e.message) {
              await MySwal.fire('Error!', e.message, 'error');
            }
          }
        },
      });
    },
    [MySwal, acceptQ, pagination, query, search],
  );
  //
  const rejectSponsorRequests = useCallback(
    (id: string) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: 'Do You want Reject this SponsorRequests!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#4250ce',
        confirmButtonText: 'Yes, Reject it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: 'Processing!',
            didOpen: () => {
              MySwal.showLoading();
            },
            allowOutsideClick: () => !MySwal.isLoading(),
          }).then(async () => {
            console.log('');
          });
          try {
            rejectQ({
              variables: {
                updateSponsorship: {
                  status: 'REJECTED',
                  sponsorshipId: id,
                },
              },
            }).then(async () => {
              MySwal.hideLoading();
              search(query, pagination);
              await MySwal.fire('Success!', 'SponsorRequests reject successfully.', 'success');
            });
          } catch (e: any) {
            if (e.message) {
              await MySwal.fire('Error!', e.message, 'error');
            }
          }
        }
      });
    },
    [MySwal, rejectQ, pagination, query, search],
  );
  useEffect(() => {
    (async () => {
      search(query, pagination);
    })();
  }, [pagination, query, search]);

  return {
    columns: [
      // { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'orphanName', accessor: 'orphanName', align: 'left' },
      { Header: 'donorName', accessor: 'donorName', align: 'left' },
      // { Header: 'grade', accessor: 'grade', align: 'left' },
      { Header: 'block', accessor: 'block', align: 'left' },
      { Header: 'amount', accessor: 'amount', align: 'left' },
      // { Header: 'date', accessor: 'date', align: 'left' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],

    rows: dataItems.map(
      (e: {
        id: string;
        amount: string;
        orphan: any;
        donor: any;
        date: string;
        sponsorshipBlock: any;
        hide: boolean;
      }) => ({
        id: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.id}
          </MDTypography>
        ),
        amount: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.amount}
          </MDTypography>
        ),
        orphanName: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.orphan.name}
          </MDTypography>
        ),
        donorName: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.donor.name}
          </MDTypography>
        ),
        block: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.sponsorshipBlock.year + '' + e.sponsorshipBlock.block}
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.date}
          </MDTypography>
        ),
        grade: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.orphan.grade}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={e.hide ? 'Inactive' : 'Active'}
              color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <>
            <MDButton
              onClick={() => acceptSponsorRequests(e.id)}
              color="success"
              fontWeight="medium"
              style={{ marginRight: 5 }}>
              Accept
            </MDButton>
            <MDButton
              onClick={() => rejectSponsorRequests(e.id)}
              color="primary"
              fontWeight="medium">
              Reject
            </MDButton>
          </>
        ),
      }),
    ),

    total,
    pagination,
    setPagination,
    query,
    setQuery,
  };
}
