import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IAccountDetails } from '../../IOrphanForm';

type IAccountDetailsFormProps = IAccountDetails & {
  updateFields: (fields: Partial<IAccountDetails>) => void;
};

const AccountDetailsForm = (props: IAccountDetailsFormProps) => {
  const { bank, swiftCode, branch, accountName, accountNo, updateFields } = props;

  return (
    <>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" required>
            Bank
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="bank"
            label="Bank"
            name="bank"
            value={bank}
            onChange={(e) => updateFields({ bank: e.target.value })}>
            {[
              { id: '1', value: 'BOC' },
              { id: '2', value: 'HNB' },
              { id: '3', value: 'Peoples' },
              { id: '4', value: 'Sampath' },
              { id: '5', value: 'Commercial' },
              { id: '6', value: 'Amana' },
              { id: '7', value: 'Seylan' },
              { id: '8', value: 'NTB' },
            ].map((e: { id: string; value: string }) => (
              <MenuItem value={e.id} key={e.id}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Swift Code"
            id="swiftCode"
            name="swiftCode"
            value={swiftCode}
            onChange={(e) => updateFields({ swiftCode: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField
            label="Branch"
            id="branch"
            name="branch"
            value={branch}
            onChange={(e) => updateFields({ branch: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Account Name"
            id="accountName"
            name="accountName"
            value={accountName}
            onChange={(e) => updateFields({ accountName: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Account Number"
            id="accountNumber"
            name="accountNumber"
            value={accountNo}
            onChange={(e) => updateFields({ accountNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default AccountDetailsForm;
