/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { FC, useState } from 'react';
import { Grid, Card, Modal } from '@mui/material';
import MDBox from '../../../../components/MDBox';
import MDTypography from '../../../../components/MDTypography';
import Utils from '../../../../utils/Utils';
import pdf from '../../../../assets/images/pdf.jpg';

interface OrphanDetailsProps {
  title: string;
  data: any;
  isDisplayFiles?: boolean;
  files?: any;
}

const OrphanDetails: FC<OrphanDetailsProps> = ({ title, data, isDisplayFiles, files }) => {
  const labels: any[] = [];
  const values: any[] = [];
  const shadow = true;

  const [selectedImage, setSelectedImage] = useState<string>('');

  const openImage = (imageUrl: any) => {
    setSelectedImage(imageUrl);
  };

  const openPDF = (pdfUrl: any) => {
    window.open(pdfUrl, '_blank');
  };

  const closeImage = () => {
    setSelectedImage('');
  };

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(data).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      labels.push(Utils.camelCaseToWords(el));
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(data).forEach((el) => values.push(el));

  return (
    <Card sx={{ height: '100%', boxShadow: !shadow ? 'none' : 'inherit' }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        {/* <MDTypography component={Link} to={action.route} variant="body2" color="secondary">
          <Tooltip title={action.tooltip} placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </MDTypography> */}
      </MDBox>
      <MDBox p={2}>
        {/* <MDBox>
          <hr style={{ color: 'gray', borderTop: '1px' }} />
        </MDBox> */}
        <MDBox>
          {labels.map((label, key) => (
            <MDBox key={label} display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {label}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;{values[key]}
              </MDTypography>
            </MDBox>
          ))}
        </MDBox>
        {isDisplayFiles && (
          <div style={{ position: 'absolute', bottom: '0' }}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              Files :
            </MDTypography>

            {files?.length > 0 ? (
              <Grid container spacing={1}>
                {files
                  .filter((url: string) => Utils.isImageUrl(url))
                  .map((url: string, index: number) => (
                    <Grid item key={index}>
                      <img
                        src={url}
                        alt="Orphan File"
                        style={{
                          width: '100px',
                          height: '100px',
                          cursor: 'pointer',
                          objectFit: 'cover',
                        }}
                        onClick={() => openImage(url)}
                      />
                    </Grid>
                  ))}
                {files
                  .filter((url: string) => Utils.isPDFUrl(url))
                  .map((url: string, index: number) => (
                    <Grid item key={index}>
                      <img
                        src={pdf}
                        alt="Orphan File"
                        style={{
                          width: '100px',
                          height: '100px',
                          cursor: 'pointer',
                          objectFit: 'cover',
                        }}
                        onClick={() => openPDF(url)}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <MDTypography>No files found.</MDTypography>
            )}

            {/* <MDButton
              variant="contained"
              color="info"
              startIcon={<CloudDownload />}
              sx={{ marginTop: '10px' }}>
              Download as Zip
            </MDButton> */}
            <Modal
              open={Boolean(selectedImage)}
              onClose={closeImage}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <div>
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ maxWidth: '600px', maxHeight: '800px', objectFit: 'contain' }}
                />
              </div>
            </Modal>
          </div>
        )}
      </MDBox>
    </Card>
  );
};

export default OrphanDetails;
