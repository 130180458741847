import React, { forwardRef, ForwardRefRenderFunction } from 'react';

// Custom styles for MDInput
import MDInputRoot from './MDInputRoot';

// Typechecking props for the MDInput
interface MDInputProps {
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  [x: string]: any;
}

const MDInput: ForwardRefRenderFunction<any, MDInputProps> = (
  { error = false, success = false, disabled = false, ...rest },
  ref,
) => <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />;

export default forwardRef<any, MDInputProps>(MDInput);
