import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from './context';
import { ApplicationProvider } from './connection/ApplicationContext';
import { ApolloProvider } from '@apollo/client';
import { graphqlClient } from './graphql';

ReactDOM.render(
  <BrowserRouter>
    <ApplicationProvider>
      <MaterialUIControllerProvider>
        <ApolloProvider client={graphqlClient}>
          <App />
        </ApolloProvider>
      </MaterialUIControllerProvider>
    </ApplicationProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
