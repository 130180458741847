// Material Dashboard 2 PRO React base styles
import colors from '../../base/colors';

const { text } = colors;

export default {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};
