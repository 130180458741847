import React from 'react';
import { IOrphanFiles } from '../../IOrphanForm';
import { Grid } from '@mui/material';
import DragDropFileUpload from '../../../../components/FileUpload/DragDropFileUpload';

type IFileUploadFormProps = IOrphanFiles & {
  updateFields: (x: any) => void;
};

const FileUploadForm = (props: IFileUploadFormProps) => {
  return (
    <>
      <Grid item xs={12}>
        <DragDropFileUpload onFileUpload={props.updateFields} />
      </Grid>
    </>
  );
};

export default FileUploadForm;
