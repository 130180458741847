/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import team2 from '../../../assets/images/team-2.jpg';
import MDButton from '../../../components/MDButton';
import { useNavigate } from 'react-router-dom';
import { Popover } from '@mui/material';
import styled from 'styled-components';
import MDAvatar from '../../../components/MDAvatar';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_ORPHAN, GET_ORPHANS } from '../../../queries/orphans';

type DataTablePagination = { page: number; size: number };

const MPopover = styled(Popover)`
  .MuiPopover-paper {
    margin: 10px;
    background-color: white;
  }
`;

export default function data() {
  const MySwal = withReactContent(Swal);
  const [dataItems, setDataItems] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [pagination, setPagination] = useState<DataTablePagination>({ page: 0, size: 10 });
  const [query, setQuery] = useState<any>({});
  const hoverId = useRef<string>();
  const { refetch } = useQuery(GET_ORPHANS, {
    variables: {
      skip: 0,
      take: pagination.size,
    },
  });
  const [deleteOrphanQ] = useMutation(DELETE_ORPHAN);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    hoverId.current = event.currentTarget.id.slice(0, -1);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const navigator = useNavigate();

  const search = useCallback(
    (query: any, pagination: any) => {
      setLoading(true);
      refetch({
        skip: pagination.page * pagination.size,
        take: pagination.size,
      })
        .then((value) => {
          setDataItems(value.data?.orphans.data);
          setTotal(value.data?.orphans.count ?? 0);
        })
        .finally(() => setLoading(false));
    },
    [refetch],
  );

  const addOrphan = useCallback(async () => {
    navigator('/new-orphan');
  }, [navigator]);

  const editOrphan = useCallback(
    async (el: any) => {
      navigator(`/view-orphan/${el}`);
    },
    [navigator],
  );
  //
  const deleteOrphan = useCallback(
    (id: string) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: 'Do You want Delete this Orphan!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#4250ce',
        confirmButtonText: 'Yes, Delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: 'Processing!',
            didOpen: () => {
              MySwal.showLoading();
            },
            allowOutsideClick: () => !MySwal.isLoading(),
          }).then(async () => {
            console.log('');
          });
          try {
            deleteOrphanQ({
              variables: {
                deleteOrphanId: id,
              },
            }).then(async () => {
              MySwal.hideLoading();
              search(query, pagination);
              await MySwal.fire('Success!', 'Orphan removed successfully.', 'success');
            });
          } catch (e: any) {
            if (e.message) {
              await MySwal.fire('Error!', e.message, 'error');
            }
          }
        }
      });
    },
    [MySwal, deleteOrphanQ, pagination, query, search],
  );
  useEffect(() => {
    (async () => {
      search(query, pagination);
    })();
  }, [pagination, query, search]);

  const Author: (x: { image: string; name: string }) => JSX.Element = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="xl" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'name', accessor: 'name', align: 'left' },
      { Header: 'bcNo', accessor: 'bcNo', align: 'left' },
      { Header: 'mentor', accessor: 'mentor', align: 'left' },
      { Header: 'manager', accessor: 'manager', align: 'center' },
      { Header: 'zone', accessor: 'zone', align: 'center' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],

    rows:
      dataItems?.map(
        (e: {
          id: string;
          id2: string;
          name: string;
          bcNo: any;
          mentor: any;
          manager: any;
          zone: any;
        }) => ({
          id: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {e.id2}
            </MDTypography>
          ),
          name: <Author image={team2} name={e.name} />,
          bcNo: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {e.bcNo}
            </MDTypography>
          ),
          mentor: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {e.mentor}
            </MDTypography>
          ),
          manager: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {e.manager}
            </MDTypography>
          ),
          zone: (
            <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
              {e.zone}
            </MDTypography>
          ),
          action: (
            <>
              <MDButton
                onClick={() => editOrphan(e.id)}
                color="info"
                fontWeight="medium"
                style={{ marginRight: 5 }}>
                View/Edit
              </MDButton>
              <MDButton onClick={() => deleteOrphan(e.id)} color="primary" fontWeight="medium">
                Delete
              </MDButton>
            </>
          ),
        }),
      ) ?? [],
    add: addOrphan,
    total,
    pagination,
    setPagination,
    query,
    setQuery,
  };
}
