// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import DataTable from '../../examples/Tables/DataTable';

// Data
import adminsTableData from './data/adminsTableData';
import Icon from '@mui/material/Icon';
import MDButton from '../../components/MDButton';
import React, { FC, useCallback, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TablePagination } from '@mui/material';
import TextField from '@mui/material/TextField';

const Admins: FC = () => {
  const { columns, rows, add, total, pagination, setPagination, query, setQuery, isSuperAdmin } =
    adminsTableData();
  const [filterSelectObj, setFilterSelectObj] = useState<any[]>([
    {
      name: 'Status',
      columnProp: 'isDisabled',
      options: [
        { value: '', lable: 'none' },
        { value: true, lable: 'InActive' },
        { value: false, lable: 'Active' },
      ],
      modelValue: '',
      select: true,
    },
    {
      name: 'role',
      columnProp: 'type',
      options: [
        { value: '', lable: 'none' },
        { value: 'SUPER_ADMIN', lable: 'Super Admin' },
        { value: 'ADMIN', lable: 'Admin' },
      ],
      modelValue: '',
      select: true,
    },
  ]);

  const resetFilters = useCallback(() => {
    setQuery({});
    setFilterSelectObj(
      filterSelectObj.map((value) => ({
        ...value,
        modelValue: '',
      })),
    );
  }, [filterSelectObj, setQuery]);

  const filterChange = useCallback(
    (filter: { name: string; columnProp: string; options: any[] }, event: any) => {
      const query1 = { ...query };
      setFilterSelectObj(
        filterSelectObj.map((value) => {
          if (filter.columnProp === value.columnProp) {
            return {
              ...value,
              modelValue: event.target?.value ?? event,
            };
          }
          return value;
        }),
      );
      if (event.target?.value === '') {
        delete query1[filter.columnProp];
      } else {
        query1[filter.columnProp] = event.target?.value ?? event;
      }
      setQuery(query1);
    },
    [filterSelectObj, query, setQuery],
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MDTypography variant="h6" color="white">
                      All Admins
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    display="flex"
                    direction="row">
                    {isSuperAdmin && (
                      <MDButton color="#FFF" onClick={() => add()}>
                        <Icon fontSize="large">person_add_alt</Icon>
                      </MDButton>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox px={2} pt={3}>
                <Grid container spacing={3}>
                  {filterSelectObj.map(
                    (
                      filter: {
                        name: string;
                        select: boolean;
                        columnProp: string;
                        modelValue: string;
                        options: { value: string; lable: string }[];
                      },
                      index,
                    ) => (
                      <Grid item xs={4} key={index}>
                        <FormControl style={{ height: '100%' }} fullWidth>
                          {filter.select ? (
                            <>
                              <InputLabel id={filter.columnProp + '-demo-simple-select-label'}>
                                Filter {filter.name}
                              </InputLabel>
                              <Select
                                style={{ height: '100%' }}
                                labelId={filter.columnProp + '-demo-simple-select-label'}
                                id={filter.columnProp}
                                value={filter.modelValue}
                                label={'Filter ' + filter.name}
                                name={filter.columnProp}
                                onChange={(event) => filterChange(filter, event)}>
                                {filter.options.map((e) => (
                                  <MenuItem value={e.value} key={e.value}>
                                    {e.lable}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          ) : (
                            <TextField
                              label={filter.name}
                              id={filter.columnProp}
                              value={filter.modelValue}
                              name={filter.name}
                              onChange={(event) => filterChange(filter, event)}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    ),
                  )}
                  <Grid item xs={4}>
                    <MDButton
                      onClick={resetFilters}
                      color="warning"
                      fontWeight="medium"
                      style={{ marginRight: 5 }}>
                      Reset
                    </MDButton>
                  </Grid>
                </Grid>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TablePagination
                  sx={{
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input':
                      {
                        fontWeight: 'bold',
                        marginBottom: 0,
                      },
                  }}
                  style={{ display: 'contents' }}
                  count={total}
                  page={pagination?.page}
                  onPageChange={(e, page) =>
                    setPagination({ page: page, size: pagination?.size ?? 10 })
                  }
                  rowsPerPage={pagination.size}
                  onRowsPerPageChange={(e) => setPagination({ page: 0, size: +e.target.value })}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Admins;
