import { gql } from '@apollo/client';

export const GET_SPONSORSHIPS = gql`
  query Sponsorships($skip: Int, $take: Int, $query: SponsorshipsQuery) {
    sponsorships(skip: $skip, take: $take, query: $query) {
      data {
        status
        sponsorshipBlock {
          block
          id
          year
        }
        orphan {
          name
          id
        }
        id
        donor {
          id
          name
        }
        amount
      }
      count
    }
  }
`;
