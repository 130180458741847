// react-router-dom components
import { Link } from 'react-router-dom';

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import React, { FC, useState } from 'react';

// Typechecking props for the Breadcrumbs
interface BreadcrumbsProp {
  icon: any;
  title: string;
  route: string | any[];
  light?: boolean;
}

const Breadcrumbs: FC<BreadcrumbsProp> = (props) => {
  const { icon, title, route } = props;
  const [light] = useState<boolean>(props?.light ?? false);
  const routes = route.slice(0, -1);

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          '& .MuiBreadcrumbs-separator': {
            color: (x: any) => (light ? x.palette.white.main : x.palette.grey[600]),
          },
        }}>
        <Link to="/">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? 'white' : 'dark'}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}>
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {typeof routes !== 'string' &&
          routes?.map((el) => (
            <Link to={`/${el}`} key={el}>
              <MDTypography
                component="span"
                variant="button"
                fontWeight="regular"
                textTransform="capitalize"
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{ lineHeight: 0 }}>
                {el}
              </MDTypography>
            </Link>
          ))}
        <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? 'white' : 'dark'}
          sx={{ lineHeight: 0 }}>
          {title.replace('-', ' ')}
        </MDTypography>
      </MuiBreadcrumbs>
    </MDBox>
  );
};

export default Breadcrumbs;
