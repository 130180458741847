import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IOrphanDetails } from '../../IOrphanForm';

type IOrphanageDetailsFormProps = IOrphanDetails & {
  updateFields: (fields: Partial<IOrphanDetails>) => void;
};

const OrphangeDetailsForm = (props: IOrphanageDetailsFormProps) => {
  const {
    yn,
    orphanId,
    zone,
    manager,
    currentMentor,
    incomeEUR,
    houseNo,
    streetNo,
    childNo,
    comment,
    isDisabledChild,
    doesFamilyReceiveFunds,
    canSharePhotos,
    canPublishPhotos,
    isTwinOrMultipleBirths,
    introducedBy,
    recordEnteredPersonPhone,
    agreementToBeContactPerson,
    email,
    dateSubmitted,
    note,
    removedDate,
    removalReason,
    updateFields,
  } = props;

  return (
    <>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="YN"
            id="yn"
            name="yn"
            value={yn}
            onChange={(e) => updateFields({ yn: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Orphan ID"
            id="orphanId"
            name="orphanId"
            value={orphanId}
            onChange={(e) => updateFields({ orphanId: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Zone"
            id="zone"
            name="zone"
            value={zone}
            onChange={(e) => updateFields({ zone: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Manager"
            id="manager"
            name="manager"
            value={manager}
            onChange={(e) => updateFields({ manager: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Current Mentor"
            id="currentMentor"
            name="currentMentor"
            value={currentMentor}
            onChange={(e) => updateFields({ currentMentor: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Income in Euro"
            id="incomeEUR"
            name="incomeEUR"
            value={incomeEUR}
            onChange={(e) => updateFields({ incomeEUR: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="House Number"
            id="houseNo"
            name="houseNo"
            value={houseNo}
            onChange={(e) => updateFields({ houseNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Orphan's Street No"
            id="streetNumber"
            name="streetNumber"
            value={streetNo}
            onChange={(e) => updateFields({ streetNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Child No"
            id="childNo"
            name="childNo"
            value={childNo}
            onChange={(e) => updateFields({ childNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Comment"
            id="comment"
            name="comment"
            value={comment}
            onChange={(e) => updateFields({ comment: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" required>
            Is Disabled Child
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="isDisabledChild"
            label="Is Disabled Child"
            name="isDisabledChild"
            value={isDisabledChild}
            onChange={(e) => updateFields({ isDisabledChild: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" required>
            Does this feamil receive funds ?
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="doesFamilyReceiveFunds"
            label="Does this feamil receive funds ?"
            name="doesFamilyReceiveFunds"
            value={doesFamilyReceiveFunds}
            onChange={(e) => updateFields({ doesFamilyReceiveFunds: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" required>
            Can Share Photos ?
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="canSharePhotos"
            label="Can Share Photos ?"
            name="canSharePhotos"
            value={canSharePhotos}
            onChange={(e) => updateFields({ canSharePhotos: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Can Publish Photos ?</InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="canPublishPhotos"
            label="Can Publish Photos ?"
            name="canPublishPhotos"
            value={canPublishPhotos}
            onChange={(e) => updateFields({ canPublishPhotos: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Is Twin or Multiple Births ?</InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="isTwinOrMultipleBirths"
            label="Is Twin or Multiple Births ?"
            name="isTwinOrMultipleBirths"
            value={isTwinOrMultipleBirths}
            onChange={(e) => updateFields({ isTwinOrMultipleBirths: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Introduced By"
            id="introducedBy"
            name="introducedBy"
            value={introducedBy}
            onChange={(e) => updateFields({ introducedBy: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Record Entered Person Phone"
            id="recordEnteredPersonPhone"
            name="recordEnteredPersonPhone"
            value={recordEnteredPersonPhone}
            onChange={(e) => updateFields({ recordEnteredPersonPhone: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label9">Agreement To Be Contact Person</InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label9"
            id="agreementToBeContactPerson"
            label="Agreement To Be Contact Person"
            name="agreementToBeContactPerson"
            value={agreementToBeContactPerson}
            onChange={(e) => updateFields({ agreementToBeContactPerson: e.target.value })}>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Your Email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => updateFields({ email: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Date Submitted"
            id="dateSubmitted"
            name="dateSubmitted"
            value={dateSubmitted}
            onChange={(e) => updateFields({ dateSubmitted: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Note"
            id="note"
            name="note"
            value={note}
            onChange={(e) => updateFields({ note: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Removed Date"
            id="removedDate"
            name="removedDate"
            value={removedDate}
            onChange={(e) => updateFields({ removedDate: e.target.value })}
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Reason for Removal"
            id="removalReason"
            name="removalReason"
            value={removalReason}
            onChange={(e) => updateFields({ removalReason: e.target.value })}
            fullWidth
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default OrphangeDetailsForm;
