import React, { forwardRef, ForwardRefRenderFunction } from 'react';
// @mui material components
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';

// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';

// custom styles for the NotificationItem
import menuItem from './styles';

// Typechecking props for the NotificationItem
interface NotificationItemProps {
  icon: any;
  title: string;
  [x: string]: any;
}

const NotificationItem: ForwardRefRenderFunction<any, NotificationItemProps> = (
  { icon, title, ...rest },
  ref,
) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
        {title}
      </MDTypography>
    </MDBox>
  </MenuItem>
);

export default forwardRef<any, NotificationItemProps>(NotificationItem);
