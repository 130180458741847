import React, { forwardRef, ForwardRefRenderFunction } from 'react';

// Custom styles for the MDBadge
import MDBadgeRoot from './MDBadgeRoot';

// Typechecking props of the MDBadge
interface MDBadgeProps {
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  variant?: 'gradient' | 'contained';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  circular?: boolean;
  indicator?: boolean;
  border?: boolean;
  children?: any;
  container?: boolean;
  [x: string]: any;
}

const MDBadge: ForwardRefRenderFunction<any, MDBadgeProps> = (
  {
    color = 'info',
    variant = 'gradient',
    size = 'sm',
    circular = false,
    indicator = false,
    border = false,
    container = false,
    children = false,
    ...rest
  },
  ref,
) => (
  <MDBadgeRoot
    {...rest}
    ownerState={{ color, variant, size, circular, indicator, border, container, children }}
    ref={ref}
    color="default">
    {children}
  </MDBadgeRoot>
);

export default forwardRef<any, MDBadgeProps>(MDBadge);
