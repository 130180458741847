import React, { FC, useEffect } from 'react';

// react-router-dom components
import { useLocation, useNavigate } from 'react-router-dom';

// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from '../../../context';
import { useUserInfo } from '../../../connection/Application';

const DashboardLayout: FC<{ children: any }> = ({ children }) => {
  const { controller, dispatch } = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const user = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/sign-in');
    }
  }, [navigate, user]);

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <MDBox
      sx={(x: any) => ({
        p: 3,
        position: 'relative',

        [x.breakpoints.up('xl')]: {
          marginLeft: miniSidenav ? x.functions.pxToRem(120) : x.functions.pxToRem(274),
          transition: x.transitions.create(['margin-left', 'margin-right'], {
            easing: x.transitions.easing.easeInOut,
            duration: x.transitions.duration.standard,
          }),
        },
      })}>
      {children}
    </MDBox>
  );
};

export default DashboardLayout;
