import React, { FC, useCallback, useState } from 'react';
import { TextField, Popover, InputAdornment, IconButton } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { withStyles } from '@mui/styles';
import moment from 'moment';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { enGB } from 'date-fns/locale';
import styled from 'styled-components'; // theme css file

const styles = {
  calendarWrapper: {
    padding: '16px',
  },
};

const MPopover = styled(Popover)`
  .MuiPopover-paper {
    margin: 10px;
    background-color: white;
  }
`;

export const DateRangePicker: FC<{
  value: any;
  setRange: (x: { fromDate: string; toDate: string }) => void;
}> = (props) => {
  const [dateFormat] = useState('DD/MM/YYYY');
  const [state, setState] = useState({
    displayCalendar: false,
    inputValue: '',
    anchorEl: null,
    fromDate: props?.value?.fromDate ?? undefined,
    toDate: props?.value?.toDate ?? undefined,
  });

  const onAdornmentClick = useCallback(
    (e: any) => {
      setState({
        ...state,
        displayCalendar: true,
        anchorEl: e.currentTarget,
      });
    },
    [state],
  );

  const onPopoverClose = useCallback(
    (e: any, reason: any) => {
      console.log(reason);
      setState({ ...state, displayCalendar: false, anchorEl: null });
    },
    [state],
  );

  const onSelectDateRanges = useCallback(
    (e: any) => {
      let { startDate, endDate } = e.selection;

      startDate = moment(startDate).set('hour', 0).set('minute', 1);
      startDate = startDate.isValid() ? startDate.toDate() : undefined;

      endDate = moment(endDate).set('hour', 0).set('minute', 1).add(1, 'day').subtract(2, 'minute');
      endDate = endDate.isValid() ? endDate.toDate() : undefined;

      let inputValue = '';
      if (startDate) inputValue += moment(startDate).format(dateFormat);
      if (endDate) inputValue += ' - ' + moment(endDate).format(dateFormat);

      props.setRange({ fromDate: startDate, toDate: endDate });
      console.log(startDate, endDate);
      setState({ ...state, fromDate: startDate, toDate: endDate, inputValue });
    },
    [dateFormat, props, state],
  );

  const processInputValue = useCallback(
    (value: any) => {
      let [fromDate, toDate] = value.split('-').map((elm: any) => elm.trim());

      fromDate = moment(fromDate, dateFormat);
      fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

      toDate = moment(toDate, dateFormat);
      toDate = toDate.isValid() ? toDate.toDate() : undefined;

      return { fromDate, toDate };
    },
    [dateFormat],
  );

  const onInputChange = useCallback(
    (e: any) => {
      const inputValue = e.target.value;
      const { fromDate, toDate } = processInputValue(inputValue);

      setState({ ...state, inputValue, fromDate, toDate });
    },
    [processInputValue, state],
  );

  return (
    <div>
      <TextField
        label="Date Time Picker"
        fullWidth={true}
        value={state.inputValue}
        inputProps={{ disabled: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <MPopover
        open={state.displayCalendar}
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ padding: 0 }}
        onClose={onPopoverClose}>
        <div style={{ padding: '16px', backgroundColor: '#fff' }}>
          <DateRange
            ranges={[
              {
                startDate: state.fromDate,
                endDate: state.toDate,
                key: 'selection',
              },
            ]}
            onChange={onSelectDateRanges}
            maxDate={new Date()}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
            locale={enGB}
          />
        </div>
      </MPopover>
    </div>
  );
};

export default withStyles(styles, { name: 'DateRangePicker' })(DateRangePicker);
