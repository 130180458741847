import React, { FC, useState, useEffect } from 'react';

// react-router components
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
// @material-ui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';

// Material Dashboard 2 React example components
import Breadcrumbs from '../../Breadcrumbs';
import NotificationItem from '../../Items/NotificationItem';

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarMobileMenu } from './styles';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav } from '../../../context';
import { useConnection } from '../../../connection/Application';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const DashboardNavbar: FC<{ absolute?: boolean; light?: boolean; isMini?: boolean }> = ({
  absolute = false,
  light = false,
  isMini = false,
}) => {
  const MySwal = withReactContent(Swal);
  const [navbarType, setNavbarType] = useState<
    'fixed' | 'absolute' | 'sticky' | 'static' | 'relative' | undefined
  >();
  const { controller, dispatch } = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const [openMenu1, setOpenMenu1] = useState<any>(false);
  const route = useLocation().pathname.split('/').slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu1 = (event: any) => setOpenMenu1(event.currentTarget);
  const handleCloseMenu1 = () => setOpenMenu1(false);
  const connection = useConnection();
  const navigate = useNavigate();

  const logoutUser = async () => {
    // console.log('logout user from the website');

    MySwal.fire({
      title: 'Are you sure?',
      text: 'Do You want Logout this Site!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      cancelButtonColor: '#4250ce',
      confirmButtonText: 'Yes, Logout it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        // MySwal.fire({
        //   title: 'Processing!',
        //   didOpen: () => {
        //     MySwal.showLoading();
        //   },
        //   allowOutsideClick: () => !MySwal.isLoading(),
        // }).then(async () => {
        //   console.log('');
        // });
        try {
          connection.setToken(undefined);
          navigate('/sign-in');
        } catch (e: any) {
          if (e.message) {
            await MySwal.fire('Error!', e.message, 'error');
          }
        }
      }
    });
  };

  const renderMenu1 = () => (
    <Menu
      anchorEl={openMenu1}
      anchorReference={undefined}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu1)}
      onClose={handleCloseMenu1}
      sx={{ mt: 2 }}>
      <Link onClick={logoutUser} to={''}>
        <NotificationItem icon={<PowerSettingsNewIcon />} title="logout" />
      </Link>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle: (x: {
    palette: { dark: any; white: any; text: any };
    functions: { rgba: any };
  }) => any = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme: any) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme: any) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? 'white' : 'inherit'}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu1}>
                <Icon sx={(theme: any) => iconsStyle(theme)}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}>
                <Icon sx={(theme: any) => iconsStyle(theme)} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  disableRipple*/}
              {/*  color="inherit"*/}
              {/*  // eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*  // @ts-ignore*/}
              {/*  sx={navbarIconButton}*/}
              {/*  aria-controls="notification-menu"*/}
              {/*  aria-haspopup="true"*/}
              {/*  variant="contained"*/}
              {/*  onClick={handleOpenMenu}>*/}
              {/*  <Icon sx={(theme: any) => iconsStyle(theme)}>notifications</Icon>*/}
              {/*</IconButton>*/}
              {/*{renderMenu()}*/}
              {renderMenu1()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
