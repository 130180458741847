// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import DataTable from '../../examples/Tables/DataTable';

// Data
import orphansTableData from './data/orphansTableData';
import Icon from '@mui/material/Icon';
import MDButton from '../../components/MDButton';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import { useConnection } from '../../connection/Application';

const Orphan: FC = () => {
  const { columns, rows, add, total, pagination, setPagination, query, setQuery } =
    orphansTableData();
  const connection = useConnection();
  const [filterSelectObj, setFilterSelectObj] = useState<any[]>([
    {
      name: 'Search',
      columnProp: 'search',
      options: [],
      modelValue: '',
      select: false,
    },
    {
      name: 'Status',
      columnProp: 'hide',
      options: [
        { value: '', lable: 'none' },
        { value: 'true', lable: 'InActive' },
        { value: 'false', lable: 'Active' },
      ],
      modelValue: '',
      select: true,
    },
    {
      name: 'Category',
      columnProp: 'category',
      options: [],
      modelValue: '',
      select: true,
    },
    {
      name: 'Grade',
      columnProp: 'grade',
      options: [],
      modelValue: '',
      select: true,
    },
    {
      name: 'Main Title',
      columnProp: 'mainTitle',
      options: [],
      filter: ['category', 'grade'],
      modelValue: '',
      select: true,
    },
    {
      name: 'Sub Title',
      columnProp: 'subTitle',
      options: [],
      filter: ['category', 'grade', 'mainTitle'],
      modelValue: '',
      select: true,
    },
  ]);

  const getCategoriesToSelect = useCallback(async () => {
    const categories = await connection.get('category/data/all');
    return categories.response.body;
  }, [connection]);

  const getGradesToSelect = useCallback(async () => {
    const grades = await connection.get('grade/data/all');
    return grades.response.body;
  }, [connection]);

  const getMainTitlesToSelect = useCallback(async () => {
    const mainTitles = await connection.get('main-title/data/all');
    return mainTitles.response.body;
  }, [connection]);

  const getSubTitlesToSelect = useCallback(async () => {
    const subTitles = await connection.get('sub-title/data/all');
    return subTitles.response.body;
  }, [connection]);

  useEffect(() => {
    const fetchData = async () => {
      const grades = await getGradesToSelect();
      const categories = await getCategoriesToSelect();
      const mainTitles = await getMainTitlesToSelect();
      const subTitles = await getSubTitlesToSelect();
      setFilterSelectObj(
        filterSelectObj.map((value) => {
          if (value.columnProp === 'category') {
            return {
              ...value,
              options: [
                { value: '', lable: 'none' },
                ...categories.map((e: any) => ({ value: e.id, lable: e.value })),
              ],
            };
          }
          return value;
        }),
      );
    };
    // fetchData();
    // eslint-disable-next-line
  }, []);

  const resetFilters = useCallback(() => {
    setQuery({});
    setFilterSelectObj(
      filterSelectObj.map((value) => ({
        ...value,
        modelValue: '',
      })),
    );
  }, [filterSelectObj, setQuery]);

  const filterChange = useCallback(
    (
      filter: { name: string; columnProp: string; options: any[]; filter?: string[] },
      event: any,
    ) => {
      const query1 = { ...query };
      setFilterSelectObj(
        filterSelectObj.map((value) => {
          if (filter.columnProp === value.columnProp) {
            return {
              ...value,
              modelValue: event.target?.value.trim() ?? event.trim(),
            };
          }
          if ((value.filter ?? []).find((e: string) => e === filter.columnProp)) {
            return {
              ...value,
              modelValue: '',
            };
          }
          return value;
        }),
      );
      if (event.target?.value === '') {
        delete query1[filter.columnProp];
      } else {
        query1[filter.columnProp] =
          event.target?.value.trim().toLowerCase() ?? event.trim().toLowerCase();
      }
      setQuery(query1);
    },
    [filterSelectObj, query, setQuery],
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MDTypography variant="h6" color="white">
                      All Orphans
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    justifyContent="flex-end"
                    display="flex"
                    direction="row">
                    <MDButton color="#FFF" onClick={() => add()}>
                      <Icon fontSize="large">add_alt</Icon>
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox px={2} pt={3}>
                <DataTable
                  entriesPerPage={{
                    defaultValue: pagination?.size ?? 100,
                    entries: [10, 25, 50, 100, 200, 500],
                  }}
                  table={{ columns, rows }}
                  isSorted={false}
                  // entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TablePagination
                  sx={{
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input':
                      {
                        fontWeight: 'bold',
                        marginBottom: 0,
                      },
                  }}
                  style={{ display: 'contents' }}
                  count={total}
                  rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
                  page={pagination?.page}
                  onPageChange={(e, page) =>
                    setPagination({ page: page, size: pagination?.size ?? 100 })
                  }
                  rowsPerPage={pagination.size}
                  onRowsPerPageChange={(e) => setPagination({ page: 0, size: +e.target.value })}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Orphan;
