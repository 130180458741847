import React from 'react';

// @mui icons
import Icon from '@mui/material/Icon';
import { Group, Paid, CurrencyExchange, ManageAccounts } from '@mui/icons-material';
import Admins from './layouts/admins';
import Sponsorships from './layouts/sponsorships';
import SponsorRequests from './layouts/sponsorRequests';
import Orphan from './layouts/orphans';
import NewOrphan from './layouts/orphans/NewOrphan';
import ViewOrphan from './layouts/orphans/ViewOrphan';

const routes: {
  type: string;
  name: string;
  key: string;
  icon: JSX.Element;
  route: string;
  component: JSX.Element;
  disableShow?: boolean;
}[] = [
  {
    type: 'collapse',
    name: 'Orphans',
    key: 'grades',
    icon: <Group />,
    route: '/orphans',
    component: <Orphan />,
  },
  {
    type: 'collapse',
    name: 'Sponsorships',
    key: 'sponsorships',
    icon: <Paid />,
    route: '/sponsorships',
    component: <Sponsorships />,
  },
  {
    type: 'collapse',
    name: 'Sponsor Requests',
    key: 'sponsor-requests',
    icon: <CurrencyExchange />,
    route: '/sponsor-requests',
    component: <SponsorRequests />,
  },
  // {
  //   type: 'collapse',
  //   name: 'Summary',
  //   key: 'summary',
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: '/summary',
  //   component: <Summary />,
  // },
  {
    type: 'collapse',
    disableShow: true,
    name: 'New Orphan',
    key: 'newOrphan',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/new-orphan',
    component: <NewOrphan />,
  },
  {
    type: 'collapse',
    disableShow: true,
    name: 'View Orphan',
    key: 'viewOrphan',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/view-orphan/:id',
    component: <ViewOrphan />,
  },
  {
    type: 'collapse',
    disableShow: true,
    name: 'Edit Orphan',
    key: 'editOrphan',
    icon: <Icon fontSize="small">table_view</Icon>,
    route: '/edit-orphan/:id',
    component: <NewOrphan />,
  },
  {
    type: 'collapse',
    name: 'Admins',
    key: 'admins',
    icon: <ManageAccounts />,
    route: '/admins',
    component: <Admins />,
  },
];

export default routes;
