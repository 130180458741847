import React, { forwardRef, ForwardRefRenderFunction } from 'react';

// Custom styles for MDAvatar
import MDAvatarRoot from './MDAvatarRoot';

type BgColor =
  | 'transparent'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'light'
  | 'dark';

interface MDAvatarProps {
  bgColor: BgColor;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  shadow: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset';
  [x: string]: any;
}

const MDAvatar: ForwardRefRenderFunction<any, MDAvatarProps> = (
  { bgColor = 'transparent', size = 'md', shadow = 'none', ...rest },
  ref,
) => {
  return <MDAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />;
};

export default forwardRef<any, MDAvatarProps>(MDAvatar);
