// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 React components
import MDBox from '../../../../components/MDBox';

// Material Dashboard 2 React example components
import PageLayout from '../../../../examples/LayoutContainers/PageLayout';
import React, { FC } from 'react';

// Typechecking props for the BasicLayout
interface BasicLayoutProps {
  image: string;
  children: any;
}

// Authentication pages components

const BasicLayout: FC<BasicLayoutProps> = ({ image, children }) => {
  return (
    <PageLayout>
      {/* <DefaultNavbar */}
      {/*  action={{ */}
      {/*    type: "external", */}
      {/*    route: "https://localhost:3000", */}
      {/*    label: "free download", */}
      {/*    color: "dark", */}
      {/*  }} */}
      {/* /> */}
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: (x: any) =>
            image &&
            `${x.functions.linearGradient(
              x.functions.rgba(x.palette.gradients.dark.main, 0.6),
              x.functions.rgba(x.palette.gradients.dark.state, 0.6),
            )}, url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer light /> */}
    </PageLayout>
  );
};

export default BasicLayout;
