import { gql } from '@apollo/client';

export const GET_ADMIN = gql`
  query Staffs($skip: Int, $take: Int, $query: StaffQuery) {
    staffs(skip: $skip, take: $take, query: $query) {
      data {
        id
        name
        email
        contact
        type
        isDisabled
      }
      count
    }
  }
`;

export const ADD_ADMIN = gql`
  mutation AddStaff($newStaff: NewStaffInput!) {
    addStaff(newStaff: $newStaff) {
      code
      success
      message
      data {
        id
        name
        email
        contact
        type
      }
    }
  }
`;

export const UPDATE_ADMIN = gql`
  mutation UpdateStaff($updateStaffId: ID!, $updateStaff: UpdateStaffInput!) {
    updateStaff(id: $updateStaffId, updateStaff: $updateStaff) {
      code
      success
      message
      data {
        id
        name
        email
        contact
        type
      }
    }
  }
`;
