/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDAvatar from '../../../components/MDAvatar';
import MDBadge from '../../../components/MDBadge';

import team2 from '../../../assets/images/team-2.jpg';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import MDButton from '../../../components/MDButton';
import { useUserInfo } from '../../../connection/Application';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_ADMIN, GET_ADMIN, UPDATE_ADMIN } from '../../../queries/admin';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
// Typechecking props for the MDButton
interface RenderFormProps {
  add: boolean;
  adminData: any;
  userId: string;
  type: string;
}

const RenderForm1: ForwardRefRenderFunction<
  {
    getAdmin: () => {
      id: string;
      name: string;
      email: string;
      type: string;
      isDisabled: boolean;
      contact: string;
      password?: string;
      confirmPassword?: string;
    };
  },
  RenderFormProps
> = (
  {
    add = false,
    userId = '',
    type = 'ADMIN',
    adminData = {
      id: '',
      name: '',
      email: '',
      contact: '',
      type: '',
      isDisabled: false,
      password: '',
      confirmPassword: '',
    },
  },
  ref,
) => {
  const [admin, setAdmin] = useState(adminData);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleChange = (event: any) => {
    setAdmin({
      ...admin,
      [event.target.name]: event.target.value,
    });
  };

  useImperativeHandle(ref, () => ({
    getAdmin() {
      return admin;
    },
  }));
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} style={{ paddingTop: '54px' }}>
        <TextField
          fullWidth
          label="Name"
          id="name"
          name="name"
          value={admin.name}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          id="email"
          name="email"
          disabled={!add}
          value={admin.email}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label1">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label1"
            id="gradeId"
            value={admin.type}
            label="Type"
            name="type"
            disabled={!add}
            onChange={handleChange}>
            {[
              { id: 'ADMIN', value: 'Admin' },
              { id: 'SUPER_ADMIN', value: 'Super Admin' },
            ].map((e: { id: string; value: string }) => (
              <MenuItem value={e.id} key={e.id}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Mobile"
          id="contact"
          name="contact"
          value={admin.contact}
          onChange={handleChange}
        />
      </Grid>
      {add && (
        <>
          <Grid item xs={12}>
            <OutlinedInput
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Password"
              name="password"
              id="password"
              value={admin.abn}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Confirm Password"
              name="confirmPassword"
              id="confirmPassword"
              value={admin.abn}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </>
      )}
      {!add && type !== 'ADMIN' && (
        <Grid item xs={12} justifyContent={'flex-start'} style={{ display: 'flex' }}>
          <Typography variant="button" fontWeight="regular" color="text" style={{ lineHeight: 3 }}>
            Disable
          </Typography>
          <Switch
            checked={admin.isDisabled}
            disabled={admin.id === userId}
            onChange={() =>
              handleChange({ target: { name: 'isDisabled', value: !admin.isDisabled } })
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

const RenderForm = forwardRef<
  {
    getAdmin: () => {
      id: string;
      name: string;
      email: string;
      contact: string;
      type: string;
      isDisabled: boolean;
    };
  },
  RenderFormProps
>(RenderForm1);

type DataTablePagination = { page: number; size: number };

export default function data() {
  const MySwal = withReactContent(Swal);
  const addAdminRef = useRef<any>();
  const user = useUserInfo();
  const editAdminRef = useRef<any>();
  const [dataItems, setDataItems] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<DataTablePagination>({ page: 0, size: 10 });
  const [query, setQuery] = useState<any>({});
  const { refetch } = useQuery(GET_ADMIN, {
    variables: {
      skip: 0,
      take: pagination.size,
    },
  });
  const [addAdminQ] = useMutation(ADD_ADMIN);
  const [updateAdminQ] = useMutation(UPDATE_ADMIN);
  const [deleteAdminQ] = useMutation(UPDATE_ADMIN);

  const search = useCallback(
    (query: any, pagination: any) => {
      setLoading(true);
      refetch({
        skip: pagination.page * pagination.size,
        take: pagination.size,
        query,
      })
        .then((value) => {
          setDataItems(value.data.staffs.data);
          setTotal(value.data?.staffs.count ?? 0);
        })
        .finally(() => setLoading(false));
    },
    [refetch],
  );

  const addAdmin = useCallback(async () => {
    await MySwal.fire({
      titleText: 'Add New Admin',
      html: (
        <RenderForm
          userId={user?.id ?? ''}
          add={true}
          adminData={{
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            isDisable: false,
            password: '',
            confirmPassword: '',
          }}
          type={user?.type ?? ''}
          ref={addAdminRef}
        />
      ),
      showCancelButton: true,
      confirmButtonText: 'Save!',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#4250ce',
      cancelButtonColor: '#dc3545',
      preConfirm: async () => {
        const admin = addAdminRef.current?.getAdmin();
        if (
          admin.email === '' ||
          admin.name === '' ||
          admin.type === '' ||
          admin.contact === '' ||
          admin.password === '' ||
          admin.confirmPassword === ''
        ) {
          MySwal.showValidationMessage('Form is not completed');
        } else if (admin.confirmPassword !== admin.password) {
          MySwal.showValidationMessage('Passwords are not match');
        } else {
          MySwal.fire({
            title: 'Processing!',
            didOpen: () => {
              MySwal.showLoading();
            },
            allowOutsideClick: () => !MySwal.isLoading(),
          }).then(() => {
            console.log('');
          });
          const user = { ...admin };
          delete (user as any).confirmPassword;
          delete (user as any).password;
          delete (user as any).id;
          try {
            addAdminQ({
              variables: {
                newStaff: {
                  staffType: admin.type,
                  password: admin.password,
                  name: admin.name,
                  email: admin.email,
                  contact: admin.contact,
                  isDisabled: false,
                },
              },
            }).then(async () => {
              MySwal.hideLoading();
              search(query, pagination);
              await MySwal.fire('Success!', 'New admin added to the platform', 'success');
            });
          } catch (e: any) {
            if (e.message) {
              await MySwal.fire('Error!', e.message, 'error');
            }
          }
        }
      },
    });
  }, [MySwal, addAdminQ, pagination, query, search, user?.id, user?.type]);

  const editAdmin = useCallback(
    async (el: any) => {
      await MySwal.fire({
        titleText: 'Edit Admin',
        html: (
          <RenderForm
            userId={user?.id ?? ''}
            add={false}
            adminData={{
              id: el.id,
              email: el.email,
              name: el.name,
              contact: el.contact,
              type: el.type,
            }}
            type={user?.type ?? ''}
            ref={editAdminRef}
          />
        ),
        showCancelButton: true,
        confirmButtonText: 'Save!',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#4250ce',
        cancelButtonColor: '#dc3545',
        preConfirm: async () => {
          const admin = editAdminRef.current.getAdmin();
          console.log(admin);
          if (
            admin.email === '' ||
            admin.name === '' ||
            admin.contact === '' ||
            admin.type === ''
          ) {
            MySwal.showValidationMessage('Form is not completed');
          } else {
            MySwal.fire({
              title: 'Processing!',
              didOpen: () => {
                MySwal.showLoading();
              },
              allowOutsideClick: () => !MySwal.isLoading(),
            }).then(async () => {
              console.log('');
            });
            try {
              const user = { ...admin };
              delete user.id;
              updateAdminQ({
                variables: {
                  updateStaffId: admin.id,
                  updateStaff: {
                    name: admin.name,
                    email: admin.email,
                    contact: admin.contact,
                    isDisabled: admin.isDisabled,
                  },
                },
              }).then(async () => {
                MySwal.hideLoading();
                search(query, pagination);
                await MySwal.fire('Success!', 'Admin details edited successfully', 'success');
              });
            } catch (e: any) {
              if (e.message) {
                await MySwal.fire('Error!', e.message, 'error');
              }
            }
          }
        },
      });
    },
    [MySwal, user?.id, user?.type, updateAdminQ, search, query, pagination],
  );

  const Author: (x: { image: string; name: string; email: string }) => JSX.Element = ({
    image,
    name,
    email,
  }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job: (x: { title: string; description: string }) => JSX.Element = ({
    title,
    description,
  }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  useEffect(() => {
    (async () => {
      search(query, pagination);
    })();
  }, [pagination, query, search]);

  return {
    columns: [
      { Header: 'user', accessor: 'author', width: '45%', align: 'left' },
      { Header: 'role', accessor: 'function', align: 'left' },
      { Header: 'status', accessor: 'status', align: 'center' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],
    rows: dataItems.map(
      (e: { name: string; email: string; isDisabled: boolean; type: string; id: string }) => ({
        author: <Author image={team2} name={`${e.name}`} email={e.email} />,
        function: (
          <Job title={e.type === 'ADMIN' ? 'Admin' : 'Super Admin'} description="Organization" />
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={e.isDisabled ? 'Inactive' : 'Active'}
              color={e.isDisabled ? 'primary' : 'success'}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <>
            {user?.type === 'SUPER_ADMIN' ? (
              <MDButton
                onClick={() => editAdmin(e)}
                color="info"
                fontWeight="medium"
                style={{ marginRight: 5 }}>
                Edit
              </MDButton>
            ) : e.type === 'ADMIN' && e.id === user?.id ? (
              <MDButton
                onClick={() => editAdmin(e)}
                color="info"
                fontWeight="medium"
                style={{ marginRight: 5 }}>
                Edit
              </MDButton>
            ) : (
              <></>
            )}
          </>
        ),
      }),
    ),
    add: addAdmin,
    total,
    pagination,
    setPagination,
    query,
    setQuery,
    isSuperAdmin: user?.type === 'SUPER_ADMIN',
  };
}
