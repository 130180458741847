import { gql } from '@apollo/client';

export const GET_ORPHANS = gql`
  query GetOrphans {
    orphans {
      data {
        id
        id2
        name
        bcNo
        mentor
        manager
        zone
      }
      count
    }
  }
`;

export const GET_ORPHAN = gql`
  query Orphan($orphanId: ID!) {
    orphan(id: $orphanId) {
      id
      id2
      name
      surname
      dob
      gender
      city
      bcNo
      birthDistrict
      birthPlace
      school
      grade
      photo
      fatherName
      fatherDOB
      fatherPOB
      fatherDOD
      fatherCOD
      fatherDeathDistrict
      fatherBurial
      deathCertNo
      motherName
      motherDOB
      isMotherAlive
      motherDOD
      motherId
      motherNIC
      motherJob
      sibiling
      guardian
      guardianNIC
      guardianPhone1
      guardianPhone2
      mentor
      mentorRel
      mentorPhone
      bank
      swiftCode
      branch
      accountName
      accountNo
      yn
      orphanId
      zone
      manager
      currentMentor
      incomeEUR
      houseNo
      streetNo
      childNo
      comment
      isDisabledChild
      doesFamilyReceiveFunds
      canSharePhotos
      canPublishPhotos
      isTwinOrMultipleBirths
      introducedBy
      recordEnteredPersonPhone
      agreementToBeContactPerson
      email
      dateSubmitted
      note
      removedDate
      removalReason
      assets
    }
  }
`;

export const GET_NOTES = gql`
  query GetNote($orphanId: String!) {
    getNote(orphanId: $orphanId) {
      id
      date
      note
      orphanId
    }
  }
`;

export const ADD_NOTE = gql`
  mutation AddNote($newNote: NoteInput!) {
    addNote(newNote: $newNote) {
      code
      success
      message
      data {
        id
        date
        note
        orphanId
      }
    }
  }
`;

export const ADD_ORPHAN = gql`
  mutation AddOrphan($newOrphan: NewOrphanInput!) {
    addOrphan(newOrphan: $newOrphan) {
      code
      success
      message
      data {
        id
        id2
        name
      }
    }
  }
`;

export const EDIT_ORPHAN = gql`
  mutation UpdateOrphan($updateOrphanId: ID!, $updatedOrphan: NewOrphanInput!) {
    updateOrphan(id: $updateOrphanId, updatedOrphan: $updatedOrphan) {
      code
      success
      message
      data {
        id2
        id
        name
      }
    }
  }
`;

export const DELETE_ORPHAN = gql`
  mutation DeleteOrphan($deleteOrphanId: ID!) {
    deleteOrphan(id: $deleteOrphanId) {
      code
      data {
        id
      }
      message
      success
    }
  }
`;

export const ADD_FILES = gql`
  mutation MultiUpload($fileInput: MultiFileInput!) {
    multiUpload(fileInput: $fileInput)
  }
`;
