import React from 'react';
import { Grid, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import { IParentsDetails } from '../../IOrphanForm';

type IParentsDetailsFormProps = IParentsDetails & {
  updateFields: (fields: Partial<IParentsDetails>) => void;
};

const ParentsDetailsForm = (props: IParentsDetailsFormProps) => {
  const {
    fatherName,
    fatherDOB,
    fatherPOB,
    fatherDOD,
    fatherCOD,
    fatherDeathDistrict,
    fatherBurial,
    deathCertNo,
    motherName,
    motherDOB,
    isMotherAlive,
    motherDOD,
    motherId,
    motherNIC,
    motherJob,
    sibiling,
    guardian,
    guardianNIC,
    guardianPhone1,
    guardianPhone2,
    mentor,
    mentorRel,
    mentorPhone,
    updateFields,
  } = props;
  return (
    <>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Name"
            id="fatherName"
            name="fatherName"
            value={fatherName}
            onChange={(e) => updateFields({ fatherName: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Father's Date of Birth"
            id="fatherDOB"
            name="fatherDOB"
            value={fatherDOB}
            onChange={(e) => updateFields({ fatherDOB: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Place of Birth"
            id="fatherPOB"
            name="fatherPOB"
            value={fatherPOB}
            onChange={(e) => updateFields({ fatherPOB: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Date of Death"
            id="fatherDOD"
            name="fatherDOD"
            value={fatherDOD}
            onChange={(e) => updateFields({ fatherDOD: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Cause of Death"
            id="fatherCOD"
            name="fatherCOD"
            value={fatherCOD}
            onChange={(e) => updateFields({ fatherCOD: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Death District"
            id="fatherDeathDistrict"
            name="fatherDeathDistrict"
            value={fatherDeathDistrict}
            onChange={(e) => updateFields({ fatherDeathDistrict: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Place of Burial"
            id="fatherBurial"
            name="fatherBurial"
            value={fatherBurial}
            onChange={(e) => updateFields({ fatherBurial: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Father's Death Certificate No"
            id="deathCertNo"
            name="deathCertNo"
            value={deathCertNo}
            onChange={(e) => updateFields({ deathCertNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mother's Name"
            id="motherName"
            name="motherName"
            value={motherName}
            onChange={(e) => updateFields({ motherName: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Mother's Date of Birth"
            id="motherDOB"
            name="motherDOB"
            value={motherDOB}
            onChange={(e) => updateFields({ motherDOB: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="is-mother-alive-label" required>
            Is Mother Alive
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="is-mother-alive-label"
            id="isMotherAlive"
            label="Is Mother ALive"
            name="isMotherAlive"
            value={isMotherAlive}
            onChange={(e) => updateFields({ isMotherAlive: e.target.value })}
            required>
            {[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ].map((e: { id: boolean; value: string }) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <MenuItem value={e.id} key={e.id + ''}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Mother's Date of Death"
            id="motherDOD"
            name="motherDOD"
            value={motherDOD}
            onChange={(e) => updateFields({ motherDOD: e.target.value })}
            fullWidth
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mother ID"
            id="motherId"
            name="motherId"
            value={motherId}
            onChange={(e) => updateFields({ motherId: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mother's NIC"
            id="motherNIC"
            name="motherNIC"
            value={motherNIC}
            onChange={(e) => updateFields({ motherNIC: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mother's Profession"
            id="motherJob"
            name="motherJob"
            value={motherJob}
            onChange={(e) => updateFields({ motherJob: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Sibiling"
            id="sibiling"
            name="sibiling"
            value={sibiling}
            onChange={(e) => updateFields({ sibiling: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Guardian"
            id="guardian"
            name="guardian"
            value={guardian}
            onChange={(e) => updateFields({ guardian: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Guardian NIC"
            id="guardianNIC"
            name="guardianNIC"
            value={guardianNIC}
            onChange={(e) => updateFields({ guardianNIC: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Guardian Phone 1"
            id="guardianPhone1"
            name="guardianPhone1"
            value={guardianPhone1}
            onChange={(e) => updateFields({ guardianPhone1: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Guardian Phone 2"
            id="guardianPhone2"
            name="guardianPhone2"
            value={guardianPhone2}
            onChange={(e) => updateFields({ guardianPhone2: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mentor"
            id="mentor"
            name="mentor"
            value={mentor}
            onChange={(e) => updateFields({ mentor: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mentor Relationship"
            id="mentorRel"
            name="mentorRel"
            value={mentorRel}
            onChange={(e) => updateFields({ mentorRel: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Mentor's Phone"
            id="mentorPhone"
            name="mentorPhone"
            value={mentorPhone}
            onChange={(e) => updateFields({ mentorPhone: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default ParentsDetailsForm;
