// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from '../../../context';
import React, { FC } from 'react';

// Typechecking props for the DataTableHeadCell
interface DataTableHeadCellProps {
  width?: string | number;
  children: any;
  sorted?: false | 'none' | 'asce' | 'desc';
  align?: 'left' | 'right' | 'center';
  [x: string]: any;
}

const DataTableHeadCell: FC<DataTableHeadCellProps> = ({
  width = 'auto',
  children,
  sorted = 'none',
  align = 'left',
  ...rest
}) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox
      component="th"
      width={width}
      py={1.5}
      px={3}
      sx={(x: any) => ({
        borderBottom: `${x.borders.borderWidth[1]} solid ${x.palette.light.main}`,
      })}>
      <MDBox
        {...rest}
        position="relative"
        textAlign={align}
        color={darkMode ? 'white' : 'secondary'}
        opacity={0.7}
        sx={(x: any) => ({
          fontSize: x.typography.size.xxs,
          fontWeight: x.typography.fontWeightBold,
          textTransform: 'uppercase',
          cursor: sorted && 'pointer',
          userSelect: sorted && 'none',
        })}>
        {children}
        {sorted && (
          <MDBox
            position="absolute"
            top={0}
            right={align !== 'right' ? '16px' : 0}
            left={align === 'right' ? '-5px' : 'unset'}
            sx={(x: any) => ({
              fontSize: x.typography.size.lg,
            })}>
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === 'asce' ? 'text' : 'secondary'}
              opacity={sorted === 'asce' ? 1 : 0.5}>
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={0}
              color={sorted === 'desc' ? 'text' : 'secondary'}
              opacity={sorted === 'desc' ? 1 : 0.5}>
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};

export default DataTableHeadCell;
