// @mui material components
import Link from '@mui/material/Link';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

// Material Dashboard 2 PRO React base styles
import typography from '../../assets/theme/base/typography';
import React, { FC } from 'react';

// Typechecking props for the Footer
interface FooterProps {
  company?: { href: string; name: string };
  links?: any[];
}

const Footer: FC<FooterProps> = ({ company = { href: '', name: '' }, links = [] }) => {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: 'column', lg: 'row' }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}>
        &copy; Powered {new Date().getFullYear()} by
        <Link href={'https://neurale.io/'} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;{'Neurale Computing'}&nbsp;
          </MDTypography>
        </Link>
      </MDBox>
      <MDBox
        component="ul"
        sx={(x: any) => ({
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          listStyle: 'none',
          mt: 3,
          mb: 0,
          p: 0,

          [x.breakpoints.up('lg')]: {
            mt: 0,
          },
        })}>
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
};

export default Footer;
