import { gql } from '@apollo/client';

export const LOGIN = gql`
  query ExampleQuery($authData: AuthData!) {
    login(authData: $authData) {
      code
      message
      token
    }
  }
`;
