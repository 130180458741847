import config from '../config';
import { useMemo } from 'react';
import moment from 'moment';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import MomentTz from 'moment-timezone';

export default class Utils {
  static CommonImageFileFormats = '.jpg,.jpeg,.png,.gif,.tif,.tiff,.bmp';
  static CommonDocumentFileFormats = '.docx,.doc,.pdf,.txt,.odt,.rtf';

  static formatPhoneNumber = (value: string) => {
    return formatPhoneNumberIntl(value)
      .split(' ')
      .map((v, i) => (i === 1 ? `(${v})` : v))
      .join(' ');
  };

  static mergeClasses = (
    ...classNames: (string | undefined | Record<string, boolean | undefined>)[]
  ): string => {
    return classNames
      .map((value) => {
        if (typeof value !== 'string' && value !== undefined) {
          const v: Record<string, boolean | undefined> = value;
          const s = Object.entries(v)
            .filter((e) => e[1])
            .map((e) => e[0])
            .join(' ');
          return s.trim() === '' ? undefined : s.trim();
        } else {
          return value;
        }
      })
      .filter((value) => !!value)
      .join(' ');
  };

  static removeNull = <T>(value: T): any => {
    if (value !== null && value !== undefined && typeof value === 'object') {
      return Object.fromEntries(
        Object.entries(value)
          .map((v) => [v[0], Utils.removeNull(v[1])])
          .filter((v) => v[1] !== null),
      );
    }
    return value as any;
  };

  static getFileUrl(path?: string): string | undefined {
    if (!path) return undefined;
    return `${process.env.REACT_APP_File_URL}${path}`;
  }

  static useOmitFields(value: any, ...fields: string[]) {
    return useMemo(() => {
      const val = { ...value };
      fields.forEach((f) => delete val[f]);
      return val;
    }, [value, fields]);
  }

  static mergeArrays<T>(current: T[], newValues: T[], idExtractor: (t: T) => string | number): T[] {
    const cur = [...current];
    for (const n of newValues) {
      const index = cur.findIndex((value) => idExtractor(value) === idExtractor(n));
      if (index === -1) {
        cur.push(n);
      } else {
        cur[index] = n;
      }
    }
    return cur;
  }

  static cleanHtml = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return new XMLSerializer().serializeToString(doc);
    // return html.replace(/<br>/g, '<br/>');
  };
  static getTime = (modifiedTime: any) => {
    return moment.utc(modifiedTime).local().format('YYYY-MMM-DD h:mm A');
  };

  static deleteFields = <T = any, R = T>(value: T, ...fields: (keyof T | string)[]): R => {
    fields.forEach((f) => delete (value as any)[f]);
    return value as unknown as R;
  };

  static selectFields = <T = any, R = T>(value: T, ...fields: (keyof T | string)[]): R => {
    const val: any = {};
    fields.forEach((f) => (val[f] = (value as any)[f]));
    return val as unknown as R;
  };

  static dateToString(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  static formatMonth(date: string) {
    if (date === 'Present') {
      return date;
    }
    return moment(date, 'YYYY-MM-DD').format('MMM/YYYY');
  }

  static formatDate(date: string | number) {
    return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  static epochToDateTime(epoch: number, longFormat?: boolean) {
    return moment(epoch).format(
      longFormat ? 'dddd, MMMM Do, YYYY h:mm:ss A' : 'DD/MM/YYYY h:mm:ss A',
    );
  }

  static dateSince(epoch: number) {
    return moment(epoch).fromNow();
  }

  static s3LinkToHttp(link: string) {
    const s3Link = link.replace('s3://', '');
    return `${config.api.fileUrl}/${s3Link.trim()}`;
  }

  static moment = (inp?: MomentTz.MomentInput) => {
    return MomentTz(inp).tz('Asia/Colombo');
  };

  static normalizeURIComponent = (uriComponent: string) => {
    if (!uriComponent) {
      return uriComponent;
    }
    return (uriComponent ?? '')
      .replace(/[\W_]+/g, '-')
      .replace(/[-]+/g, '-')
      .toLowerCase();
  };

  /**
   *
   * @param input - Ex: camelCaseToWords
   * @returns - Ex: camel case to words
   */
  static camelCaseToWords = (input: string) => {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  };

  static isImageUrl(url: any) {
    const regex = /\.([a-zA-Z0-9]+)(?:\?|$)/;
    const match = url.match(regex);
    return ['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(match?.[1]);
  }
  static isPDFUrl(url: any) {
    const regex = /\.([a-zA-Z0-9]+)(?:\?|$)/;
    const match = url.match(regex);
    return ['pdf'].includes(match?.[1]);
  }
}
