import { gql } from '@apollo/client';

export const GET_SPONSOR_REQUEST = gql`
  query Sponsorships($skip: Int, $take: Int, $query: SponsorshipsQuery) {
    sponsorships(skip: $skip, take: $take, query: $query) {
      data {
        status
        sponsorshipBlock {
          block
          id
          year
        }
        orphan {
          name
          id
        }
        id
        donor {
          id
          name
        }
        amount
      }
      count
    }
  }
`;

export const GET_SPONSOR_BLOCKS = gql`
  query SponsorshipsBlocks {
    sponsorshipBlocks {
      year
      id
      block
    }
  }
`;

export const UPDATE_SPONSOR_REQUEST = gql`
  mutation UpdateSponsorship($updateSponsorship: UpdateSponsorship!) {
    updateSponsorship(updateSponsorship: $updateSponsorship) {
      success
      message
      data {
        id
        orphan {
          id
          name
        }
        donor {
          id
          name
        }
        sponsorshipBlock {
          block
          year
          id
        }
        status
        amount
      }
      code
    }
  }
`;

export const GET_DONORS_TO_SELECT = gql`
  query Donors {
    donors {
      data {
        id
        name
      }
    }
  }
`;

export const GET_ORPHANS_TO_SELECT = gql`
  query Orphans {
    orphans {
      data {
        id
        name
      }
    }
  }
`;
