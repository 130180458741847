import { useState, useEffect, FC } from 'react';
import 'antd/dist/antd.css';
// react-router components
import { Routes, Route, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 React example components
import Sidenav from './examples/Sidenav';

// Material Dashboard 2 React themes
import theme from './assets/theme';

// Material Dashboard 2 React Dark Mode themes
// import themeDark from '../theme-dark';

// Material Dashboard 2 React routes
import routes from './routes';

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from './context';

// Images
import brandWhite from './assets/images/logo-ct.png';
import brandDark from './assets/images/logo-ct-dark.png';
import SignIn from './layouts/authentication/sign-in';
import React from 'react';
import { AUTH_TOKEN, parseJwt, REFRESH_TOKEN } from './connection/ApplicationContext';
import UniversalCookie from 'universal-cookie';

const GuardedRoute: FC<{ auth?: boolean }> = ({ auth = false }) =>
  auth ? <Outlet /> : <Navigate to="/login" />;

const universalCookie = new UniversalCookie();

export default function App() {
  const { controller, dispatch } = useMaterialUIController();
  const navigate = useNavigate();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  useEffect(() => {
    if (!universalCookie.get(AUTH_TOKEN)) {
      setIsAuthenticated(false);
      navigate('/sign-in');
    } else {
      setIsAuthenticated(true);
      navigate('/orphans');
    }
    // eslint-disable-next-line
  }, [isAuthenticated, universalCookie]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  setInterval(() => {
    const token = universalCookie.get(AUTH_TOKEN);
    if (token) {
      try {
        if (token) {
          const decodedJwt = parseJwt(token);

          if (decodedJwt.exp * 1000 < Date.now()) {
            universalCookie.remove(AUTH_TOKEN);
            universalCookie.remove(REFRESH_TOKEN);
            // const res = connection.post();
          }
        }
        // here you call refresh token API
      } catch (error) {
        console.log('error', error);
      }
    }
  }, 600000);

  const getRoutes: any = (
    allRoutes: { collapse: any; route: string; key: string; component: any }[],
    isAuthenticatedValue?: boolean,
  ) =>
    allRoutes.map((route: { collapse: any; route: string; key: string; component: any }) => {
      if (route.collapse) {
        return getRoutes(route.collapse, isAuthenticatedValue);
      }

      if (route.route) {
        return (
          <Route
            path={route.route}
            key={route.key}
            element={<GuardedRoute auth={isAuthenticatedValue} />}>
            <Route path={route.route} element={route.component} />
          </Route>
        );
      }

      return null;
    });

  // const configsButton = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     width="3.25rem"
  //     height="3.25rem"
  //     bgColor="white"
  //     shadow="sm"
  //     borderRadius="50%"
  //     position="fixed"
  //     right="2rem"
  //     bottom="2rem"
  //     zIndex={99}
  //     color="dark"
  //     sx={{ cursor: "pointer" }}
  //     onClick={handleConfiguratorOpen}
  //   >
  //     <Icon fontSize="small" color="inherit">
  //       settings
  //     </Icon>
  //   </MDBox>
  // );

  return (
    <ThemeProvider theme={darkMode ? theme : theme}>
      <CssBaseline />
      {!!isAuthenticated && layout === 'dashboard' && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Aytam"
            routes={routes.filter((e) => !e.disableShow)}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes, isAuthenticated)}
        <Route path="/sign-in" element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />
        {isAuthenticated ? (
          <Route path="*" element={<Navigate to="/orphans" />} />
        ) : (
          <Route path="*" element={<Navigate to="/sign-in" />} />
        )}
        {/*<Route path="*" element={<Navigate to="/dashboard" />} />*/}
      </Routes>
    </ThemeProvider>
  );
}
