import React, { FC, useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';
// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from '../../../context';

// Typechecking props for the PageLayout
interface PageLayoutProps {
  background?: 'white' | 'light' | 'default';
  children: any;
}

const PageLayout: FC<PageLayoutProps> = ({ background = 'default', children }) => {
  const { dispatch } = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'page');
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <MDBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: 'hidden' }}>
      {children}
    </MDBox>
  );
};

export default PageLayout;
