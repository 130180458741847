// Material Dashboard 2 PRO React helper functions
import pxToRem from '../../functions/pxToRem';

export default {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`,
    },
  },
};
