// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import Footer from '../../examples/Footer';
import DataTable from '../../examples/Tables/DataTable';

// Data
import sponsorshipsTableData from './data/sponsorshipsTableData';
import MDButton from '../../components/MDButton';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TablePagination } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useQuery } from '@apollo/client';
import {
  GET_DONORS_TO_SELECT,
  GET_ORPHANS_TO_SELECT,
  GET_SPONSOR_BLOCKS,
} from '../../queries/sponsorRequest';
import moment from 'moment/moment';
import { DateRangePicker } from '../../components/DateRangePicker/DateRangePicker';
import Autocomplete from '@mui/material/Autocomplete';

const Sponsorships: FC = () => {
  const { columns, rows, total, pagination, setPagination, query, setQuery } =
    sponsorshipsTableData();
  const { data } = useQuery(GET_SPONSOR_BLOCKS);
  const donorsToSelect = useQuery(GET_DONORS_TO_SELECT);
  const orphansToSelect = useQuery(GET_ORPHANS_TO_SELECT);
  const [l, setL] = useState(false);
  const [filterSelectObj, setFilterSelectObj] = useState<any[]>([
    {
      name: 'Sponsorship Block',
      columnProp: 'sponsorshipBlockId',
      options: [],
      modelValue: '',
      select: true,
    },
    {
      name: 'Created At',
      columnProp: 'createdAt',
      fromName: 'createdAtFrom',
      toName: 'createdAtTo',
      options: [],
      modelValue: '',
      select: false,
      dateRang: true,
    },
    {
      name: 'Updated At',
      columnProp: 'updatedAt',
      fromName: 'updatedAtFrom',
      toName: 'updatedAtTo',
      options: [],
      modelValue: '',
      select: false,
      dateRang: true,
    },
    {
      name: 'Status',
      columnProp: 'status',
      options: [
        { value: '', lable: 'none' },
        { value: 'ACCEPTED', lable: 'Accepted' },
        { value: 'REJECTED', lable: 'Rejected' },
      ],
      modelValue: '',
      select: true,
    },
  ]);
  const [donors, setDonors] = useState([]);
  const [selectDonor, setSelectDonor] = useState();
  const [orphans, setOrphans] = useState([]);
  const [selectOrphan, setSelectOrphan] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const blocks = data?.sponsorshipBlocks;
      setFilterSelectObj(
        filterSelectObj.map((value) => {
          if (value.columnProp === 'sponsorshipBlockId') {
            return {
              ...value,
              options: [
                { value: '', lable: 'none' },
                ...(blocks ?? [])?.map((e: any) => ({
                  value: e.id,
                  lable: e.year + ' - ' + e.block,
                })),
              ],
            };
          }
          return value;
        }),
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      const donor = donorsToSelect?.data?.donors?.data ?? [];
      setDonors(
        donor.map((e: any) => ({
          label: e.name,
          id: e.id,
        })),
      );
      const orphan = orphansToSelect?.data?.orphans?.data ?? [];
      setOrphans(
        orphan.map((e: any) => ({
          label: e.name,
          id: e.id,
        })),
      );
    };
    fetchData();
    // eslint-disable-next-line
  }, [donorsToSelect, orphansToSelect]);

  const resetFilters = useCallback(() => {
    setL(true);
    setQuery({});
    setFilterSelectObj(
      filterSelectObj.map((value) => ({
        ...value,
        modelValue: '',
      })),
    );
    setTimeout(() => setL(false), 400);
  }, [filterSelectObj, setQuery]);

  const filterChange = useCallback(
    (
      filter: {
        name: string;
        columnProp: string;
        options: any[];
        dateRange?: boolean;
        filter?: string[];
      },
      event: any,
    ) => {
      const query1 = { ...query };
      setFilterSelectObj(
        filterSelectObj.map((value) => {
          if (filter.columnProp === value.columnProp) {
            return {
              ...value,
              modelValue: event.target?.value ?? event,
            };
          }
          if ((value.filter ?? []).find((e: string) => e === filter.columnProp)) {
            return {
              ...value,
              modelValue: '',
            };
          }
          return value;
        }),
      );
      if (event.target?.value === '') {
        delete query1[filter.columnProp];
      } else {
        if (filter?.dateRange) {
          query1[filter.options[0].fromDate] = moment(event.fromDate).format(
            'yyyy-MM-DDT00:00:00.000',
          );
          query1[filter.options[0].toDate] = moment(event.toDate).format('yyyy-MM-DDT23:59:59.000');
        } else {
          query1[filter.columnProp] = event.target?.value ?? event;
        }
      }
      setQuery(query1);
    },
    [filterSelectObj, query, setQuery],
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card sx={{ overflow: 'visible' }}>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info">
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MDTypography variant="h6" color="white">
                      All Sponsorships
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox px={2} pt={3}>
                <Grid container spacing={3}>
                  {!l &&
                    filterSelectObj.map(
                      (
                        filter: {
                          name: string;
                          select: boolean;
                          dateRang: boolean;
                          columnProp: string;
                          modelValue: any;
                          options: { value: string; lable: string }[] | any[];
                          filter?: string[];
                          fromName?: string;
                          toName?: string;
                        },
                        index,
                      ) => (
                        <Grid item xs={4} key={index}>
                          <FormControl style={{ height: '44.13px' }} fullWidth>
                            {filter.select ? (
                              <>
                                <InputLabel id={filter.columnProp + '-demo-simple-select-label'}>
                                  Filter {filter.name}
                                </InputLabel>
                                <Select
                                  style={{ height: '100%' }}
                                  labelId={filter.columnProp + '-demo-simple-select-label'}
                                  id={filter.columnProp}
                                  value={filter.modelValue}
                                  label={'Filter ' + filter.name}
                                  name={filter.columnProp}
                                  onChange={(event) => filterChange(filter, event)}>
                                  {(filter?.filter ?? []).length !== 0
                                    ? [
                                        { value: '', lable: 'none' },
                                        ...filter.options.filter(
                                          (e) =>
                                            filter.filter
                                              ?.map((e2) => {
                                                if (
                                                  (filterSelectObj.find(
                                                    (e1) => e1.columnProp === e2 ?? '',
                                                  )?.modelValue ?? '') === ''
                                                ) {
                                                  return true;
                                                }
                                                if (
                                                  e[e2] ==
                                                  filterSelectObj.find((e1) => e1.columnProp === e2)
                                                    .modelValue
                                                ) {
                                                  return true;
                                                }
                                                return false;
                                              })
                                              .find((e3) => !e3) ?? true,
                                        ),
                                      ].map((e) => (
                                        <MenuItem value={e.value} key={e.value}>
                                          {e.lable}
                                        </MenuItem>
                                      ))
                                    : filter.options.map((e) => (
                                        <MenuItem value={e.value} key={e.value}>
                                          {e.lable}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </>
                            ) : filter.dateRang ? (
                              <DateRangePicker
                                value={filter.modelValue}
                                setRange={(event) => {
                                  filterChange(
                                    {
                                      name: filter.columnProp,
                                      columnProp: filter?.fromName ?? '',
                                      options: [
                                        {
                                          fromDate: filter?.fromName ?? '',
                                          toDate: filter?.toName ?? '',
                                        },
                                      ],
                                      dateRange: true,
                                    },
                                    event,
                                  );
                                }}
                              />
                            ) : (
                              <TextField
                                label={filter.name}
                                id={filter.columnProp}
                                value={filter.modelValue}
                                name={filter.name}
                                onChange={(event) => filterChange(filter, event)}
                              />
                            )}
                          </FormControl>
                        </Grid>
                      ),
                    )}
                  <Grid item xs={4}>
                    <FormControl style={{ height: '44.13px' }} fullWidth>
                      <Autocomplete
                        sx={{
                          '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                            padding: '5px !important',
                          },
                        }}
                        disablePortal
                        id={'donors'}
                        value={selectDonor}
                        getOptionLabel={(option: any) => `${option.label}`}
                        options={donors}
                        renderInput={(params) => <TextField {...params} label="Filter Donors" />}
                        onChange={(event, value) => {
                          if (value) {
                            setQuery({ ...query, donorId: value.id });
                          } else {
                            const x = { ...query };
                            delete x.donorId;
                            setQuery({ ...x });
                          }
                          setSelectDonor(value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl style={{ height: '44.13px' }} fullWidth>
                      <Autocomplete
                        sx={{
                          '&.MuiAutocomplete-root .MuiOutlinedInput-root': {
                            padding: '5px !important',
                          },
                        }}
                        disablePortal
                        id={'orphans'}
                        value={selectOrphan}
                        getOptionLabel={(option: any) => `${option.label}`}
                        options={orphans}
                        renderInput={(params) => <TextField {...params} label="Filter Orphans" />}
                        onChange={(event, value) => {
                          if (value) {
                            setQuery({ ...query, orphanId: value.id });
                          } else {
                            const x = { ...query };
                            delete x.orphanId;
                            setQuery({ ...x });
                          }
                          setSelectOrphan(value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <MDButton
                      onClick={resetFilters}
                      color="warning"
                      fontWeight="medium"
                      style={{ marginRight: 5 }}>
                      Reset
                    </MDButton>
                  </Grid>
                </Grid>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={{ defaultValue: pagination.size }}
                  showTotalEntries={false}
                  noEndBorder
                />
                <TablePagination
                  sx={{
                    '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-input':
                      {
                        fontWeight: 'bold',
                        marginBottom: 0,
                      },
                  }}
                  style={{ display: 'contents' }}
                  count={total}
                  page={pagination?.page}
                  onPageChange={(e, page) =>
                    setPagination({ page: page, size: pagination?.size ?? 10 })
                  }
                  rowsPerPage={pagination.size}
                  onRowsPerPageChange={(e) => setPagination({ page: 0, size: +e.target.value })}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Sponsorships;
