/* eslint-disable react/prop-types */

// Soft UI Dashboard React components
import MDTypography from '../../../components/MDTypography';
import MDBadge from '../../../components/MDBadge';

import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import MDButton from '../../../components/MDButton';
import { useConnection } from '../../../connection/Application';
import Utils from '../../../utils/Utils';
import MDBox from '../../../components/MDBox';
import { useQuery } from '@apollo/client';
import { GET_SPONSORSHIPS } from '../../../queries/sponsorships';

// Typechecking props for the MDButton
interface RenderFormProps {
  add: boolean;
  mainTitlesData: any;
}

const RenderForm1: ForwardRefRenderFunction<
  {
    getSponsorships: () => {
      id: string;
      amount: string;
      orphan: string;
      donor: string;
      date: string;
      hide: boolean;
    };
  },
  RenderFormProps
> = (
  {
    add = false,
    mainTitlesData = {
      id: '',
      amount: '',
      orphan: '',
      donor: '',
      date: '',
      hide: false,
    },
  },
  ref,
) => {
  const [mainTitles, setSponsorships] = useState(mainTitlesData);
  const handleChange = (event: any) => {
    setSponsorships({
      ...mainTitles,
      [event.target.name]: event.target.value,
    });
  };

  useImperativeHandle(ref, () => ({
    getSponsorships() {
      return mainTitles;
    },
  }));
  return (
    <Grid container spacing={6}>
      <Grid item xs={12} style={{ paddingTop: '54px' }}>
        <TextField
          fullWidth
          label="Sponsorship Id"
          id="sponsorshipId"
          name="sponsorshipId"
          value={mainTitles.id}
          disabled
          // onChange={handleChange}
        />
        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label1">Grade</InputLabel>
          <Select
            labelId="demo-simple-select-label1"
            id="gradeId"
            value={mainTitles.gradeId}
            label="Grade"
            name="gradeId"
            onChange={handleChange}>
            {grades.map((e: { id: string; value: string }) => (
              <MenuItem value={e.id} key={e.id}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Amount"
          id="amount"
          name="amount"
          value={mainTitles.amount}
          disabled
          // onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Orphan Name"
          id="orphanName"
          name="orphanName"
          value={mainTitles.orphan.name}
          disabled
          // onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          rows={4}
          label="Donor Name"
          id="donorName"
          name="donorName"
          value={mainTitles.donor.name}
          disabled
          // onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          rows={4}
          label="Date"
          id="date"
          name="date"
          value={mainTitles.date}
          disabled
          // onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

const RenderForm = forwardRef<
  {
    getSponsorships: () => {
      id: string;
      amount: string;
      orphan: any;
      donor: any;
      date: string;
      hide: boolean;
    };
  },
  RenderFormProps
>(RenderForm1);

type DataTablePagination = { page: number; size: number };

export default function data() {
  const MySwal = withReactContent(Swal);
  const editSponsorshipsRef = useRef<any>();
  const [dataItems, setDataItems] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<DataTablePagination>({ page: 0, size: 10 });
  const [query, setQuery] = useState<any>({});
  const connection = useConnection();
  const { refetch } = useQuery(GET_SPONSORSHIPS, {
    variables: {
      skip: 0,
      take: pagination.size,
    },
  });

  const search = useCallback(
    (query: any, pagination: any) => {
      setLoading(true);
      refetch({
        skip: pagination.page * pagination.size,
        take: pagination.size,
        query: {
          ...query,
          status: !query.status ? ['ACCEPTED', 'REJECTED'] : [query.status],
        },
      })
        .then((value) => {
          setDataItems(value.data?.sponsorships.data);
          setTotal(value.data?.sponsorships.count ?? 0);
        })
        .finally(() => setLoading(false));
    },
    [refetch],
  );

  const editSponsorships = useCallback(
    async (el: any) => {
      await MySwal.fire({
        titleText: 'Sponsorship',
        html: (
          <RenderForm
            add={false}
            mainTitlesData={{
              id: el.id,
              amount: el.amount,
              orphan: el.orphan,
              donor: el.donor,
              date: el.date,
              hide: el.hide,
            }}
            ref={editSponsorshipsRef}
          />
        ),
        showCancelButton: true,
        showConfirmButton: false,
        confirmButtonText: 'Save!',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#4250ce',
        cancelButtonColor: '#dc3545',
        preConfirm: async () => {
          const mainTitles = editSponsorshipsRef.current.getSponsorships();
          if (
            mainTitles.description === '' ||
            mainTitles.title === '' ||
            mainTitles.categoryId === ''
          ) {
            MySwal.showValidationMessage('Form is not completed');
          } else {
            MySwal.fire({
              title: 'Processing!',
              didOpen: () => {
                MySwal.showLoading();
              },
              allowOutsideClick: () => !MySwal.isLoading(),
            }).then(async () => {
              console.log('');
            });
            try {
              const mainTitles1 = { ...mainTitles };
              delete mainTitles1.id;
              await connection.patch(`main-title/${mainTitles?.id}`, {
                mainTitle: {
                  ...Utils.removeNull({ ...mainTitles1 }),
                },
              });
              search(query, pagination);
              await MySwal.fire('Success!', 'Sponsorships details edited successfully ', 'success');
            } catch (e: any) {
              if (e.message) {
                await MySwal.fire('Error!', e.message, 'error');
              }
            }
          }
        },
      });
    },
    [
      MySwal,
      connection,
      // getCategoriesToSelect, getGradesToSelect,
      pagination,
      query,
      search,
    ],
  );
  //
  const deleteSponsorships = useCallback(
    (id: string) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: 'Do You want Delete this Sponsorships!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#dc3545',
        cancelButtonColor: '#4250ce',
        confirmButtonText: 'Yes, Delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          MySwal.fire({
            title: 'Processing!',
            didOpen: () => {
              MySwal.showLoading();
            },
            allowOutsideClick: () => !MySwal.isLoading(),
          }).then(async () => {
            console.log('');
          });
          try {
            await connection.delete(`main-title/${id}`);
            search(query, pagination);
            await MySwal.fire('Success!', 'Sponsorships removed successfully.', 'success');
          } catch (e: any) {
            if (e.message) {
              await MySwal.fire('Error!', e.message, 'error');
            }
          }
        }
      });
    },
    [MySwal, connection, pagination, query, search],
  );

  useEffect(() => {
    (async () => {
      search(query, pagination);
    })();
  }, [pagination, query, search]);

  return {
    columns: [
      // { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'orphanName', accessor: 'orphanName', align: 'left' },
      { Header: 'donorName', accessor: 'donorName', align: 'left' },
      { Header: 'block', accessor: 'block', align: 'left' },
      { Header: 'amount', accessor: 'amount', align: 'left' },
      { Header: 'status', accessor: 'status', align: 'left' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],

    rows: dataItems.map(
      (e: {
        id: string;
        amount: string;
        orphan: any;
        donor: any;
        sponsorshipBlock: any;
        status: string;
        date: string;
        hide: boolean;
      }) => ({
        id: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.id}
          </MDTypography>
        ),
        amount: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.amount}
          </MDTypography>
        ),
        orphanName: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.orphan.name}
          </MDTypography>
        ),
        donorName: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.donor.name}
          </MDTypography>
        ),
        block: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.sponsorshipBlock.year + '' + e.sponsorshipBlock.block}
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {e.date}
          </MDTypography>
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={e.status}
              color={e.status === 'ACCEPTED' ? 'success' : 'secondary'}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <>
            <MDButton
              onClick={() => editSponsorships(e)}
              color="info"
              fontWeight="medium"
              style={{ marginRight: 5 }}>
              View
            </MDButton>
          </>
        ),
      }),
    ),
    total,
    pagination,
    setPagination,
    query,
    setQuery,
  };
}
