import React from 'react';
import { Grid, FormControl, TextField, InputLabel, Select, MenuItem } from '@mui/material';
import { IPersonalDetails } from '../../IOrphanForm';

// Combine Personal Details Props with Partial & updateFields
type IPersonalDetailsFormProps = IPersonalDetails & {
  updateFields: (fields: Partial<IPersonalDetails>) => void;
};

const PersonalDetailsForm = (props: IPersonalDetailsFormProps) => {
  const {
    id,
    id2,
    name,
    surname,
    dob,
    gender,
    city,
    bcNo,
    birthDistrict,
    birthPlace,
    school,
    grade,
    updateFields,
  } = props;

  return (
    <>
      {id && (
        <Grid item xs={3}>
          <FormControl fullWidth>
            <TextField
              label="Id"
              id="id"
              name="id"
              value={id}
              disabled={true}
              onChange={(e) => updateFields({ id: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
        </Grid>
      )}
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Id 2"
            id="id2"
            name="id2"
            value={id2}
            onChange={(e) => updateFields({ id2: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Name"
            id="name"
            name="name"
            value={name}
            onChange={(e) => updateFields({ name: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Surname"
            id="surname"
            name="surname"
            value={surname}
            onChange={(e) => updateFields({ surname: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            label="Date of Birth"
            id="dob"
            name="dob"
            value={dob}
            onChange={(e) => updateFields({ dob: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" required>
            Gender
          </InputLabel>
          <Select
            style={{ height: '2.7rem' }}
            labelId="demo-simple-select-label"
            id="gender"
            label="Gender"
            name="gender"
            value={gender}
            onChange={(e) => updateFields({ gender: e.target.value })}>
            {[
              { id: 'Male', value: 'Male' },
              { id: 'Female', value: 'Female' },
            ].map((e: { id: string; value: string }) => (
              <MenuItem value={e.id} key={e.id}>
                {e.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="City"
            id="city"
            name="city"
            value={city}
            onChange={(e) => updateFields({ city: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Birth Certificate No"
            id="bcNo"
            name="bcNo"
            value={bcNo}
            onChange={(e) => updateFields({ bcNo: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Birth District"
            id="birthDistrict"
            name="birthDistrict"
            value={birthDistrict}
            onChange={(e) => updateFields({ birthDistrict: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Place of Birth"
            id="birthPlace"
            name="birthPlace"
            value={birthPlace}
            onChange={(e) => updateFields({ birthPlace: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="School"
            id="school"
            name="school"
            value={school}
            onChange={(e) => updateFields({ school: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <TextField
            label="Grade"
            id="grade"
            name="grade"
            value={grade}
            onChange={(e) => updateFields({ grade: e.target.value })}
            fullWidth
            required
          />
        </FormControl>
      </Grid>
      {/* <Grid item xs={12}>
        <FormLabel id="demo-radio-buttons-group-label">Correct Answer</FormLabel>
      </Grid>
      <Grid item xs={12}>
        {editingValue.questionType === 'boolean' && (
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            value={editingValue.correctAnswer[0]}
            defaultChecked={true}
            id="answer"
            name="answer"
            onChange={(e) => {
              setEditingValue({
                ...editingValue,
                correctAnswer: [e.target.value],
              });
            }}>
            <FormControlLabel value="true" control={<Radio />} label="True" />
            <FormControlLabel value="false" control={<Radio />} label="False" />
          </RadioGroup>
        )}
      </Grid> */}
    </>
  );
};

export default PersonalDetailsForm;
