import React from 'react';
import TimelineItem from '../../../../examples/Timeline/TimelineItem';
import MDBox from '../../../../components/MDBox';
import moment from 'moment';

interface IFormData {
  id: string;
  date: string;
  note: string;
  added_by: string;
}

interface RenderFormProps {
  data: IFormData[];
}

const ViewNote = ({ data }: RenderFormProps) => {
  return (
    <>
      <MDBox>
        {data.map((e, i) => (
          <TimelineItem
            key={i}
            color={
              i % 5 === 0
                ? 'success'
                : i % 5 === 1
                ? 'error'
                : i % 5 === 2
                ? 'info'
                : i % 5 === 3
                ? 'warning'
                : 'primary'
            }
            icon="notifications"
            title={e.note}
            dateTime={moment(+e.date).format('DD-MM-YYYY h:mm:ss')}
          />
        ))}
      </MDBox>
    </>
  );
};

export default ViewNote;
