import React, { forwardRef, ForwardRefRenderFunction } from 'react';

// Custom styles for MDButton
import MDButtonRoot from './MDButtonRoot';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from '../../context';

// Typechecking props for the MDButton
interface MDButtonProp {
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'contained' | 'outlined' | 'gradient';
  color?:
    | 'white'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'default';
  circular?: boolean;
  iconOnly?: boolean;
  children: any;
  [x: string]: any;
}

const MDButton: ForwardRefRenderFunction<any, MDButtonProp> = (
  {
    color = 'white',
    variant = 'contained',
    size = 'medium',
    circular = false,
    iconOnly = false,
    children,
    ...rest
  },
  ref,
) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDButtonRoot
      {...rest}
      ref={ref}
      color="primary"
      variant={variant === 'gradient' ? 'contained' : variant}
      size={size}
      ownerState={{ color, variant, size, circular, iconOnly, darkMode }}>
      {children}
    </MDButtonRoot>
  );
};

export default forwardRef<any, MDButtonProp>(MDButton);
