import React, { forwardRef, createContext, useContext, ForwardRefRenderFunction } from 'react';

// Material Dashboard 2 React components
import MDBox from '../MDBox';

// Custom styles for MDPagination
import MDPaginationItemRoot from '../MDPagination/MDPaginationItemRoot';

// The Pagination main context
const Context = createContext({
  variant: 'gradient',
  color: 'info',
  size: 'medium',
});

// Typechecking props for the MDPagination
interface MDPaginationProps {
  item?: boolean;
  variant?: 'gradient' | 'contained';
  color?:
    | 'white'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark';
  size?: 'small' | 'medium' | 'large';
  active?: boolean;
  children: any;
  [x: string]: any;
}

const MDPagination: ForwardRefRenderFunction<any, MDPaginationProps> = (
  {
    item = false,
    variant = 'gradient',
    color = 'info',
    size = 'medium',
    active = false,
    children,
    ...rest
  },
  ref,
) => {
  const context = item ? useContext(Context) : null;
  const paginationSize = context ? context.size : null;

  return (
    <Context.Provider value={{ variant, color, size }}>
      {item ? (
        <MDPaginationItemRoot
          {...rest}
          ref={ref}
          variant={active ? context?.variant ?? 'outlined' : 'outlined'}
          color={active ? context?.color ?? 'secondary' : 'secondary'}
          iconOnly
          circular
          ownerState={{ variant, active, paginationSize }}>
          {children}
        </MDPaginationItemRoot>
      ) : (
        <MDBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ listStyle: 'none' }}>
          {children}
        </MDBox>
      )}
    </Context.Provider>
  );
};

export default forwardRef<any, MDPaginationProps>(MDPagination);
