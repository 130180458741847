// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';
import React, { FC } from 'react';

// Typechecking props for the DataTableBodyCell
interface DataTableBodyCellProp {
  children: any;
  noBorder?: boolean;
  align?: 'left' | 'right' | 'center';
}
const DataTableBodyCell: FC<DataTableBodyCellProp> = ({
  noBorder = false,
  align = 'left',
  children,
}) => {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={(x: any) => ({
        fontSize: x.typography.size.sm,
        borderBottom: noBorder
          ? 'none'
          : `${x.borders.borderWidth[1]} solid ${x.palette.light.main}`,
      })}>
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: 'middle' }}>
        {children}
      </MDBox>
    </MDBox>
  );
};

export default DataTableBodyCell;
