import React, { forwardRef, ForwardRefRenderFunction } from 'react';
// Custom styles for MDTypography
import MDTypographyRoot from './MDTypographyRoot';

// Material Dashboard 2 React contexts
import { useMaterialUIController } from '../../context';

// Typechecking props for the MDTypography
interface MDTypographyProps {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'text'
    | 'white';
  fontWeight?: false | 'light' | 'regular' | 'medium' | 'bold';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?:
    | 'unset'
    | 'baseline'
    | 'sub'
    | 'super'
    | 'text-top'
    | 'text-bottom'
    | 'middle'
    | 'top'
    | 'bottom';
  textGradient?: boolean;
  children: any;
  opacity?: number;
  [x: string]: any;
}

const MDTypography: ForwardRefRenderFunction<any, MDTypographyProps> = (
  {
    color = 'dark',
    fontWeight = false,
    textTransform = 'none',
    verticalAlign = 'unset',
    textGradient = false,
    opacity = 1,
    children,
    ...rest
  },
  ref,
) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
        darkMode,
      }}>
      {children}
    </MDTypographyRoot>
  );
};

export default forwardRef<any, MDTypographyProps>(MDTypography);
