/**
  This file is used for controlling the dark and light state of the TimelineList and TimelineItem.
*/

import React, { createContext, FC, useContext } from 'react';

// The Timeline main context
const Timeline = createContext(false);

// Timeline context provider
const TimelineProvider: FC<{ children: any; value: boolean }> = ({ children, value }) => {
  return <Timeline.Provider value={value}>{children}</Timeline.Provider>;
};

// Timeline custom hook for using context
function useTimeline() {
  return useContext(Timeline);
}

export { TimelineProvider, useTimeline };
/* eslint-enable react/prop-types */
