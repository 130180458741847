import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import { Spin } from 'antd';
import { Grid, Card, Divider } from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Notes } from '@mui/icons-material';
import Footer from '../../examples/Footer';
import MDAvatar from '../../components/MDAvatar';
import burceMars from '../../assets/images/bruce-mars.jpg';
import OrphanDetails from './components/OrphanDetails';
import MDButton from '../../components/MDButton';
import { AddNote, ViewNote } from './components/Note';
import MDModal from '../../components/MDModal';
import { useQuery } from '@apollo/client';
import { GET_NOTES, GET_ORPHAN } from '../../queries/orphans';
import { useNavigate, useParams } from 'react-router-dom';

const ViewOrphan = () => {
  const params = useParams<'id'>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState<boolean>(false);
  const [isViewNoteModalOpen, setIsViewNoteModalOpen] = useState<boolean>(false);
  const [orphan, setOrphan] = useState<any>({});
  const [orphanId, setOrphanId] = useState<string>(params?.id ?? '');
  const [notes, setNotes] = useState([]);
  const [cc, setCC] = useState(0);

  const { refetch } = useQuery(GET_ORPHAN, {
    variables: {
      orphanId: orphanId,
    },
  });

  const getNote = useQuery(GET_NOTES, {
    variables: {
      orphanId: orphanId,
    },
  });

  useEffect(() => {
    getNote
      .refetch({
        orphanId: orphanId,
      })
      .then((value) => {
        setNotes(value.data?.getNote);
      });
  }, [getNote, orphanId, cc]);

  useEffect(() => {
    setLoading(true);
    refetch({
      orphanId: orphanId,
    })
      .then((value) => {
        setOrphan(value.data?.orphan);
      })
      .finally(() => setLoading(false));
  }, [refetch, orphanId]);

  const openAddNoteModal = () => setIsAddNoteModalOpen(true);
  const closeAddNoteModal = () => setIsAddNoteModalOpen(false);

  const openViewNoteModal = () => setIsViewNoteModalOpen(true);
  const closeViewNoteModal = () => setIsViewNoteModalOpen(false);
  const navigator = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Spin spinning={loading}>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="white">
                        Orphan Details
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox m={1} p={3}>
                  <Grid
                    container
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center">
                    <Grid item>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item>
                          <MDAvatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
                        </Grid>
                        <Grid item>
                          <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                              {orphan?.name}
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              ID: {orphan?.id2}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            color="info"
                            onClick={openAddNoteModal}>
                            <AddIcon fontSize="small" />
                            Add Note
                          </MDButton>
                        </Grid>
                        <Grid item>
                          <MDButton
                            fullWidth
                            variant="gradient"
                            color="warning"
                            onClick={openViewNoteModal}>
                            <Notes fontSize="small" /> View Note
                          </MDButton>
                        </Grid>
                        <Grid item>
                          <MDButton
                            variant="outlined"
                            color="info"
                            onClick={() => {
                              navigator(`/edit-orphan/${orphanId}`);
                            }}
                            fullWidth>
                            <EditIcon fontSize="small" /> Edit
                          </MDButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox px={3} mb={3}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6} xl={4}>
                      <OrphanDetails
                        title="Personal Details"
                        data={{
                          Name: orphan?.name,
                          Surname: orphan?.surname,
                          City: orphan?.city,
                          DOB: orphan?.dob,
                          Gender: orphan?.gender,
                          School: orphan?.school,
                          Class: orphan?.grade,
                        }}
                        isDisplayFiles={true}
                        files={orphan?.assets}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex' }}>
                      <Divider orientation="vertical" sx={{ margin: '0' }} />
                      <OrphanDetails
                        title="Parents Details"
                        data={{
                          Father: orphan?.fatherName,
                          FatherDOD: orphan?.fatherDOD,
                          CauseOfDeathFather: orphan?.fatherCOD,
                          Mother: orphan?.motherName,
                          isMotherAlive: orphan?.isMotherAlive ? 'Yes' : 'No',
                          MotherDateOfBirth: orphan?.motherDOB,
                          Guardian: orphan?.guardian,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4} sx={{ display: 'flex' }}>
                      <Divider orientation="vertical" sx={{ margin: '0' }} />
                      <OrphanDetails
                        title="Orphange Details"
                        data={{
                          zoneSub: orphan?.zone,
                          Manager: orphan?.manager,
                          Mentor23Q1: orphan?.mentorRel,
                          IncomeEuro: orphan?.incomeEUR,
                          Housenumber: orphan?.houseNo,
                          StreetAddressOfTheOrphan: orphan?.streetNo,
                          ChildNo: orphan?.childNo,
                          OurOrphanID: orphan?.orphanId,
                          Comment: orphan?.comment,
                          disabledChild: orphan?.isDisabledChild ? 'Yes' : 'No',
                          DoesThisFamilyReceiveFunds: orphan?.doesFamilyReceiveFunds ? 'Yes' : 'No',
                          CanSharePhotos: orphan?.canSharePhotos ? 'Yes' : 'No',
                          CanPublishPhotos: orphan?.canPublishPhotos ? 'Yes' : 'No',
                          isTwinOrMultipleBirths: orphan?.isTwinOrMultipleBirths ? 'Yes' : 'No',
                          IntroducedBy: orphan?.manager,
                        }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDModal title="Add New Note" open={isAddNoteModalOpen} onClose={closeAddNoteModal}>
          <AddNote
            orphanId={orphanId}
            onClose={() => {
              setCC(cc + 1);
              closeAddNoteModal();
            }}
          />
        </MDModal>
        <MDModal title="Notes" open={isViewNoteModalOpen} onClose={closeViewNoteModal}>
          <ViewNote data={notes} />
        </MDModal>
      </Spin>
      <Footer />
    </DashboardLayout>
  );
};

export default ViewOrphan;
