export interface IPersonalDetails {
  id?: string;
  id2: string;
  name: string;
  surname: string;
  dob: string;
  gender: string;
  city: string;
  bcNo: string;
  birthDistrict: string;
  birthPlace: string;
  school: string;
  grade: string;
  photo: string;
}

export interface IParentsDetails {
  fatherName: string;
  fatherDOB: string;
  fatherPOB: string;
  fatherDOD: string;
  fatherCOD: string;
  fatherDeathDistrict: string;
  fatherBurial: string;
  deathCertNo: string;
  motherName: string;
  motherDOB: string;
  isMotherAlive: boolean | string;
  motherDOD: string;
  motherId: string;
  motherNIC: string;
  motherJob: string;
  sibiling: string;
  guardian: string;
  guardianNIC: string;
  guardianPhone1: string;
  guardianPhone2: string;
  mentor: string;
  mentorRel: string;
  mentorPhone: string;
}

export interface IAccountDetails {
  bank: string;
  swiftCode: string;
  branch: string;
  accountName: string;
  accountNo: string;
}

export interface IOrphanDetails {
  yn: string;
  orphanId: string;
  zone: string;
  manager: string;
  currentMentor: string;
  incomeEUR: string;
  houseNo: string;
  streetNo: string;
  childNo: string;
  comment: string;
  isDisabledChild: boolean | string;
  doesFamilyReceiveFunds: boolean | string;
  canSharePhotos: boolean | string;
  canPublishPhotos: boolean | string;
  isTwinOrMultipleBirths: boolean | string;
  introducedBy: string;
  recordEnteredPersonPhone: string;
  agreementToBeContactPerson: boolean | string;
  email: string;
  dateSubmitted: string;
  note: string;
  removedDate: string;
  removalReason: string;
}

export interface IOrphanFiles {
  files: any[];
}

export interface IOrphanForm
  extends IPersonalDetails,
    IParentsDetails,
    IAccountDetails,
    IOrphanDetails,
    IOrphanFiles {}

export const initialOrphanForm: IOrphanForm = {
  id2: '',
  name: '',
  surname: '',
  dob: '',
  gender: '',
  city: '',
  bcNo: '',
  birthDistrict: '',
  birthPlace: '',
  school: '',
  grade: '',
  photo: '',
  fatherName: '',
  fatherDOB: '',
  fatherPOB: '',
  fatherDOD: '',
  fatherCOD: '',
  fatherDeathDistrict: '',
  fatherBurial: '',
  deathCertNo: '',
  motherName: '',
  motherDOB: '',
  isMotherAlive: false,
  motherDOD: '',
  motherId: '',
  motherNIC: '',
  motherJob: '',
  sibiling: '',
  guardian: '',
  guardianNIC: '',
  guardianPhone1: '',
  guardianPhone2: '',
  mentor: '',
  mentorRel: '',
  mentorPhone: '',
  bank: '',
  swiftCode: '',
  branch: '',
  accountName: '',
  accountNo: '',
  yn: '',
  orphanId: '',
  zone: '',
  manager: '',
  currentMentor: '',
  incomeEUR: '',
  houseNo: '',
  streetNo: '',
  childNo: '',
  comment: '',
  isDisabledChild: false,
  doesFamilyReceiveFunds: '',
  canSharePhotos: false,
  canPublishPhotos: false,
  isTwinOrMultipleBirths: false,
  introducedBy: '',
  recordEnteredPersonPhone: '',
  agreementToBeContactPerson: false,
  email: '',
  dateSubmitted: '',
  note: '',
  removedDate: '',
  removalReason: '',
  files: [],
};
