// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';

// Material Dashboard 2 React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import React, { FC, useEffect, useState, FormEvent, useCallback } from 'react';
import { Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import MDButton from '../../components/MDButton';
import Footer from '../../examples/Footer';
import { Spin } from 'antd';
import {
  AccountDetailsForm,
  FileUploadForm,
  OrphangeDetailsForm,
  ParentsDetailsForm,
  PersonalDetailsForm,
} from './components/Forms';
import { useMultistepForm } from '../../hooks/useMultistepForm';
import { IOrphanForm, initialOrphanForm } from './IOrphanForm';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_FILES, ADD_ORPHAN, EDIT_ORPHAN, GET_ORPHAN } from '../../queries/orphans';
import { v4 } from 'uuid';

export const validation: any = {
  0: [
    'id2',
    'name',
    'surname',
    'dob',
    'gender',
    'city',
    'bcNo',
    'birthDistrict',
    'birthPlace',
    'school',
    'grade',
  ],
  1: [
    'fatherName',
    'fatherDOB',
    'fatherPOB',
    'fatherDOD',
    'fatherCOD',
    'fatherDeathDistrict',
    'fatherBurial',
    'deathCertNo',
    'motherName',
    'motherDOB',
    'isMotherAlive',
    'motherId',
    'motherNIC',
    'motherJob',
    'sibiling',
    'guardian',
    'guardianNIC',
    'guardianPhone1',
    'guardianPhone2',
    'mentor',
    'mentorRel',
    'mentorPhone',
  ],
  2: ['bank', 'swiftCode', 'branch', 'accountName', 'accountNo'],
  3: [
    'yn',
    'orphanId',
    'zone',
    'manager',
    'currentMentor',
    'incomeEUR',
    'houseNo',
    'streetNo',
    'childNo',
    'comment',
    'isDisabledChild',
    'doesFamilyReceiveFunds',
    'canSharePhotos',
    'canPublishPhotos',
    'isTwinOrMultipleBirths',
    'introducedBy',
    'recordEnteredPersonPhone',
    'agreementToBeContactPerson',
    'email',
    'dateSubmitted',
    'note',
  ],
  4: [],
};

const NewOrphan: FC = () => {
  const MySwal = withReactContent(Swal);
  const [formData, setFormData] = useState<IOrphanForm>(initialOrphanForm);
  const params = useParams<'id'>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [files, setFiles] = useState([]);
  const [orphanId, setOrphanId] = useState<string>(params?.id ?? '');
  const { refetch } = useQuery(GET_ORPHAN, {
    variables: {
      orphanId: orphanId,
    },
  });

  const [addOrphan] = useMutation(ADD_ORPHAN);
  const [editOrphan] = useMutation(EDIT_ORPHAN);
  const [addFiles] = useMutation(ADD_FILES);

  useEffect(() => {
    if (orphanId) {
      setLoading(true);
      refetch({
        orphanId: orphanId,
      })
        .then((value) => {
          setFormData({ ...value.data?.orphan, files: [] });
          console.log(value.data?.orphan);
        })
        .finally(() => setLoading(false));
    }
  }, [refetch, orphanId]);

  const navigator = useNavigate();

  const updateFields = (fields: Partial<IOrphanForm>) => {
    setFormData((prev) => ({ ...prev, ...fields }));
  };
  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm([
    <PersonalDetailsForm {...formData} updateFields={updateFields} />,
    <ParentsDetailsForm {...formData} updateFields={updateFields} />,
    <AccountDetailsForm {...formData} updateFields={updateFields} />,
    <OrphangeDetailsForm {...formData} updateFields={updateFields} />,
    <FileUploadForm {...formData} updateFields={setFiles} />,
  ]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const validationFields = validation?.[currentStepIndex] ?? [];
    if (validationFields.length !== 0) {
      const isValid = !validationFields.find(
        (e: string) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formData?.[e] === '' || formData?.[e] === undefined || formData?.[e] === null,
      );
      if (!isValid) {
        await MySwal.fire({
          text: 'Form is not completed',
          icon: 'warning',
        });
        return;
      }
    }
    if (!isLastStep) return next();
    await MySwal.fire({
      // titleText: 'Accept Sponsor Request',
      title: 'Are you sure?',
      text: `Do You want ${formData.id ? 'Edit' : 'Add'}  this Orphan!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4250ce',
      cancelButtonColor: '#dc3545',
      confirmButtonText: `Yes, ${formData.id ? 'Edit' : 'Add'}  it!`,
      preConfirm: async () => {
        MySwal.fire({
          title: 'Processing!',
          didOpen: () => {
            MySwal.showLoading();
          },
          allowOutsideClick: () => !MySwal.isLoading(),
        }).then(async () => {
          console.log('');
        });
        try {
          if (!formData.id) {
            const userId = v4();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formData.files;

            addOrphan({
              variables: {
                newOrphan: {
                  id: userId,
                  ...formData,
                },
              },
            }).then(async () => {
              addFiles({
                variables: {
                  fileInput: {
                    orphanName: formData.name,
                    orphanId: userId,
                    files: files,
                  },
                },
              }).then(async () => {
                MySwal.hideLoading();
                navigator(`/orphans`);
                await MySwal.fire('Success!', 'Orphan added successfully ', 'success');
              });
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formData.__typename;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formData.files;
            editOrphan({
              variables: {
                updateOrphanId: formData.id,
                updatedOrphan: {
                  ...formData,
                },
              },
            }).then(async () => {
              addFiles({
                variables: {
                  fileInput: {
                    orphanName: formData.name,
                    orphanId: formData.id,
                    files: files,
                  },
                },
              }).then(async () => {
                MySwal.hideLoading();
                navigator(`/orphans`);
                await MySwal.fire('Success!', 'Orphan added successfully ', 'success');
              });
            });
          }
        } catch (e: any) {
          if (e.message) {
            await MySwal.fire('Error!', e.message, 'error');
          }
        }
      },
    });
  };

  const getTitles = (index: number) => {
    switch (index) {
      case 1:
        return 'Personal Details';
      case 2:
        return 'Parents Details';
      case 3:
        return 'Account Details';
      case 4:
        return 'Orphanage Details';
      case 5:
        return 'File Upload';
      default:
        return 'Details';
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Spin spinning={loading}>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <MDTypography variant="h6" color="white">
                        {formData?.id ? 'Edit' : 'New'} Orphan
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end" display="flex">
                      {/*<MDButton color="#FFF" onClick={() => add()}>*/}
                      {/*  <Icon fontSize="large">person_add_alt</Icon>*/}
                      {/*</MDButton>*/}
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox m={1} p={3}>
                  <Stack justifyContent="space-between" direction="row" alignItems="center">
                    <Typography variant="h3" mb={2}>
                      {getTitles(currentStepIndex + 1)}
                    </Typography>
                    <Typography variant="h3" mb={2}>
                      {currentStepIndex + 1} / {steps.length}
                    </Typography>
                  </Stack>

                  <MDBox component="form" role="form">
                    <Grid container spacing={3}>
                      {/* Forms Renders Here */}
                      {step}
                      {/* Forms Renders Here */}
                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                        style={{ width: '100%', marginTop: 12 }}
                        ml={{ xs: -1.5, sm: 0 }}>
                        {!isFirstStep && (
                          <MDButton
                            color="secondary"
                            fontWeight="medium"
                            style={{ marginRight: 5 }}
                            onClick={back}>
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          color="info"
                          fontWeight="medium"
                          style={{ marginRight: 5 }}
                          onClick={onSubmit}>
                          {isLastStep ? 'Submit' : 'Next'}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Spin>
      <Footer />
    </DashboardLayout>
  );
};

export default NewOrphan;
