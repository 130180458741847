import React, { createContext, Dispatch, FC, useContext, useReducer } from 'react';

interface ContextProps {
  controller: {
    miniSidenav: boolean;
    transparentSidenav: boolean;
    whiteSidenav: boolean;
    sidenavColor:
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning'
      | 'error'
      | 'light'
      | 'dark'
      | 'text'
      | 'white';
    transparentNavbar: boolean;
    fixedNavbar: boolean;
    openConfigurator: boolean;
    direction: 'rtl' | 'ltr';
    layout: string;
    darkMode: boolean;
  };
  dispatch: Dispatch<any>;
}

// The Soft UI Dashboard PRO Material main context
const MaterialUI = createContext<ContextProps>({
  controller: {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
  },
  dispatch: () => null,
});

// Material Dashboard 2 PRO React reducer
function reducer(state: any, action: any) {
  switch (action.type) {
    case 'MINI_SIDENAV': {
      return { ...state, miniSidenav: action.value };
    }
    case 'TRANSPARENT_SIDENAV': {
      return { ...state, transparentSidenav: action.value };
    }
    case 'WHITE_SIDENAV': {
      return { ...state, whiteSidenav: action.value };
    }
    case 'SIDENAV_COLOR': {
      return { ...state, sidenavColor: action.value };
    }
    case 'TRANSPARENT_NAVBAR': {
      return { ...state, transparentNavbar: action.value };
    }
    case 'FIXED_NAVBAR': {
      return { ...state, fixedNavbar: action.value };
    }
    case 'OPEN_CONFIGURATOR': {
      return { ...state, openConfigurator: action.value };
    }
    case 'DIRECTION': {
      return { ...state, direction: action.value };
    }
    case 'LAYOUT': {
      return { ...state, layout: action.value };
    }
    case 'DARKMODE': {
      return { ...state, darkMode: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Typechecking props for the MaterialUIControllerProvider
interface MaterialUIControllerProviderProps {
  children: any;
}

// Material Dashboard 2 PRO React context provider
const MaterialUIControllerProvider: FC<MaterialUIControllerProviderProps> = ({ children }) => {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: 'info',
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: 'ltr',
    layout: 'dashboard',
    darkMode: false,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  return <MaterialUI.Provider value={{ controller, dispatch }}>{children}</MaterialUI.Provider>;
};

// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      'useMaterialUIController should be used inside the MaterialUIControllerProvider.',
    );
  }

  return context;
}

// Context module functions
const setMiniSidenav = (dispatch: any, value: boolean) => dispatch({ type: 'MINI_SIDENAV', value });
const setTransparentSidenav = (dispatch: any, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_SIDENAV', value });
const setWhiteSidenav = (dispatch: any, value: boolean) =>
  dispatch({ type: 'WHITE_SIDENAV', value });
const setSidenavColor = (dispatch: any, value: string) =>
  dispatch({ type: 'SIDENAV_COLOR', value });
const setTransparentNavbar = (dispatch: any, value: boolean) =>
  dispatch({ type: 'TRANSPARENT_NAVBAR', value });
const setFixedNavbar = (dispatch: any, value: boolean) => dispatch({ type: 'FIXED_NAVBAR', value });
const setOpenConfigurator = (dispatch: any, value: boolean) =>
  dispatch({ type: 'OPEN_CONFIGURATOR', value });
const setDirection = (dispatch: any, value: string) => dispatch({ type: 'DIRECTION', value });
const setLayout = (dispatch: any, value: string) => dispatch({ type: 'LAYOUT', value });
const setDarkMode = (dispatch: any, value: boolean) => dispatch({ type: 'DARKMODE', value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  setDarkMode,
};
