// @mui material components
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 React components
import MDBox from '../../components/MDBox';

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from './styles/sidenavCollapse';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from '../../context';
import React, { FC } from 'react';

// Typechecking props for the SidenavCollapse
interface SidenavCollapseProps {
  icon: any;
  name: string;
  active?: boolean;
  [x: string]: any;
}

const SidenavCollapse: FC<SidenavCollapseProps> = ({ icon, name, active = false, ...rest }) => {
  const { controller } = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;

  return (
    <>
      <ListItem component="li">
        <MDBox
          {...rest}
          sx={(theme: any) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
              sidenavColor,
            })
          }>
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode, active })
            }>
            {typeof icon === 'string' ? (
              <Icon sx={(theme: any) => collapseIcon({ ...theme, active })}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                whiteSidenav,
                active,
              })
            }
          />
        </MDBox>
      </ListItem>
    </>
  );
};

export default SidenavCollapse;
