import React, { FC, useCallback, useEffect, useState } from 'react';

// react-router-dom components

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDInput from '../../../components/MDInput';
import MDButton from '../../../components/MDButton';

// Authentication layout components
import BasicLayout from '../components/BasicLayout';

// Images
import bgImage from '../../../assets/images/bg-sign-in-basic.jpeg';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useConnection, useUserInfo } from '../../../connection/Application';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { LOGIN } from '../../../queries/login';

interface SignInProp {
  setIsAuthenticated: any;
}

const SignIn: FC<SignInProp> = ({ setIsAuthenticated = (x: any) => console.log(x) }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState('');
  const connection = useConnection();
  const [loading, setLoading] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const user = useUserInfo();
  const { refetch } = useQuery(LOGIN, {
    variables: {
      authData: {
        email: '',
        password: '',
      },
    },
  });
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [navigate, user]);
  const onSubmit = useCallback(
    (emailValue: string, passwordValue: string) => {
      if (emailValue !== '' && passwordValue !== '') {
        setLoading(true);
        // connection.setToken(
        //   'eyJraWQiOiJ1dlJON1wveFZFb2x2NmQzMmFTejJmVWI4cXFNNkVGNWhsaEpybDBpUlJzVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0N2I5MjU4YS1hOWIzLTQ5ZDUtODY2Yi0yMjRkYjY2MzBmMzAiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTphZG1pbklkIjoiZTA1OWRlZDAtMjA5OS00Zjg0LTk3ZWMtNzRkZWVlNjI4OGVhIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfRktCM3pWN096IiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6IjQ3YjkyNThhLWE5YjMtNDlkNS04NjZiLTIyNGRiNjYzMGYzMCIsIm9yaWdpbl9qdGkiOiI4ZjNhOTJjMy02NDg5LTRlNDAtYjE3Ny0wZDI3N2RmNTU4ZGEiLCJhdWQiOiI3bHVkcGtlbmRwcjVhYTV2MDhzazkycnQzOCIsImV2ZW50X2lkIjoiMTMyMDcyZmYtNzFkOC00YzA4LWJkZDYtOGI1OGZjYmExODkyIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE3MDI0MDMwNzksIm5hbWUiOiJzdXBlciBhZG1pbiIsInBob25lX251bWJlciI6IiswNzExMTExMTExIiwiZXhwIjoxNzAyNDg5NDc5LCJjdXN0b206cm9sZSI6IltcImFkbWluXCJdIiwiaWF0IjoxNzAyNDAzMDc5LCJqdGkiOiI2ZWNiMjNiZC0wN2VkLTQwZTYtYmFhYy1iZTNlYmNkYzhhZDEiLCJlbWFpbCI6InN1cGVyYWRtaW5AZ21haWwuY29tIn0.qzVkfSwXYR7KtjFTw7hAvU_e4k6pZs57oTn1umuyqYxxDuZkuUatKA5DvRAiEoi7gPfmUo45LzP0ERKpLRK9Ya7tNJJQlioFmGvxX1bC0meWqBHTNCvRqVdMpy7hRaYO2CKASsnaWHsmkXmvy75IZmrhsNNMhhBd_p_AtEp7DZthHJ440xeXjkX7qqE6ldLCnB1MUg_qamyQdo8woeQWXSRLPy0U8HOzh6o9boxwG_Cm-1TCH0PmYloOxlQ6PnNZ3RsyIkN7umQJ8werMx1_3gEWIhb5w8xijaiADhMkkedIWEhbzqZkTSVDtOOaEjaMiWzroGTvZxlFRz7CAoKmBw',
        //   'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.O0T2MEeY8Megp30KvQoIOjfwZWtxpSncNWY7rqWhAiI5uNdpFa6OreazGIatIpw3tLzT4r7HS1db7meBIo7bdW8PthFsyldIDGVAF9IFgQfn8JNyQRuFzrmM6BtOLFp9FPYLRAfnnFZ0Ym_s2ZRFo66sAyQmUZZHlTH9Cj5k9KUj6g4xhKrzdzXEK906_NTmiil3Qpes-cFs3_XCiVD5pLxP4w35-nNdvPdzARHErumJjmLOdaOvvd0lVxhC7S8PDCpezKFKt3mkX6hY98f_YmCL5igyo_kVeape7dE9nt3-PnoIeDTjDhH0ZC47YGGAoPXfX25LG2WVAQKrae4gxQ.XN1uFRKW7yZgw2eH.rcp6GyTxMPN34M7b_ZViFZ03jh8jksvYP3beIiTrZtdtnSryusgQbqT5T8iHotAQmVs_bYPDL9jLOe-M3n1Yxfk09X2C9qhsIrXE1rTfl1L_OSJHm5EkHYOOm7kSASVMZZ0iFW_B1iUX1LCGqx1GCGXT_AxGJ6F5bNJkjeDGXZrn3O0ES0yc1oh6ob8oSNzcwfAVBlVaaMtE2rvLPFs1VQv3fechGXO6mh87WAkYO0gDYkuVrQ3s43i3D26dGHxFhFuE3nwtJVe0o78Q12TmVE42l1mKJbkOBamVgYg7Y_sD8fhcYn2hw2DhVnpBeSMB9T31Q7q9ePY7yKqUv_FY_dzcBaJSlIv2Vx88yNKV5nZ8tUGZQBWcdCGTPUSu_-QbOrkj665ZHtZ_xlpLl4J0BpIBIWeqt78NJOX1Nh9ft_J5cEcoK4s-Z8y64SRnYFEPtde3kItEst91tKHdAc56WfJKjQz1sjRGHJoLwBHVvO--ZP56RluEozIQ_qw1BBDVNHtzkffVp1-s-G2B1n_EcMmTGFCLed3iEX574b55GpsvUqEAs8SFWjY9FWmUudlNqm1leaLfbLsmi_QcLXFFHm_ZRu7hkgLGXkQ9QAoxYYoOgNXRKXorSyYhmbODsfYaOy-piR2MECtH0-yTCVkwn-F9ebhoy7VqY3M2yPx6_BTkVOEVQjb4tCq8RXguwPgb9V1QraOepvrQVGAPtqCdvPcmtpM5OfuTjzT2HSNyv6bZO0cii5Oxyr_zzJfjLlKzyv-4SaGODq3jo2sk3tVUPuOfDQpvzEzWXZ5fmJrSQxdWLx3GJQ90LCJUVpKIHqp7nezSngTzPokEdLk8zCi3OgNbFU2iwYMscevOE5IZ5tBMNIuVyS4IAOW_ZylC-BAalVuZ1-sdZXnHysm2ZGnRb8vMBYc6aSCAKp5t7PnPEOPrXFWqSkxyAsCsEe4dbYdK9ld43E6JAk5YOg7TgKtTwkodPWfbp2vAcqzpdogXIM2sXOzoYBF29-UKoyt_NDDba24DV8dw1Zb98TVXzG0IIxDjETfUl-sTzlj3aqaydUWIgpBBWy7wM-Ml-w1DJdXwNkIGoeNkgA4TlfIY9QzS_cTfvJi1OoqpnrBiaXGLz-i0VLH9UvYQfE_4nbMxMeRyAr2CqVKfenwwL5H4CVOA8cg7um8_YMVMuiaCDLApUvfvLVa9IWCa8y4du1NTXk25e6VaPx-3eW0ff1usJYEiXx7fUs1ONIU2nqk8jcyFcWtJ9AjgftsjmrRuQ2Ol8cALWRM4wdj-7acXmA2EUzQ0WpPt8ZqXi24D818EYBjW-wK7xRURsmj74Xi5qg.mfL7EKvhiHYW7ootm95FkA',
        // );
        refetch({
          authData: {
            email: emailValue,
            password: passwordValue,
          },
        })
          .then((response: any) => {
            connection.setToken(response.data.login.token, response.data.login.token);
            setIsAuthenticated(true);
            setLoading(false);
          })
          .catch((e) => {
            Swal.fire({
              title: 'Error!',
              text: e.message,
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            setLoading(false);
          });
      }
    },
    // eslint-disable-next-line
    [refetch],
  );

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center">
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}> */}
          {/*  <Grid item xs={2}> */}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white"> */}
          {/*      <FacebookIcon color="inherit" /> */}
          {/*    </MDTypography> */}
          {/*  </Grid> */}
          {/*  <Grid item xs={2}> */}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white"> */}
          {/*      <GitHubIcon color="inherit" /> */}
          {/*    </MDTypography> */}
          {/*  </Grid> */}
          {/*  <Grid item xs={2}> */}
          {/*    <MDTypography component={MuiLink} href="#" variant="body1" color="white"> */}
          {/*      <GoogleIcon color="inherit" /> */}
          {/*    </MDTypography> */}
          {/*  </Grid> */}
          {/* </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  aria-label={'ccc'}
                  color={'primary'}
                  label="Password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}>
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={loading}
                onClick={() => onSubmit(email, password)}
                fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center"> */}
            {/*  <MDTypography variant="button" color="text"> */}
            {/*    Don&apos;t have an account?{" "} */}
            {/*    <MDTypography */}
            {/*      component={Link} */}
            {/*      to="/authentication/sign-up" */}
            {/*      variant="button" */}
            {/*      color="info" */}
            {/*      fontWeight="medium" */}
            {/*      textGradient */}
            {/*    > */}
            {/*      Sign up */}
            {/*    </MDTypography> */}
            {/*  </MDTypography> */}
            {/* </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
};

export default SignIn;
