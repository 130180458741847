import React, { FC, useCallback, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import MDButton from '../../../../components/MDButton';
import { useMutation } from '@apollo/client';
import { ADD_NOTE } from '../../../../queries/orphans';
import moment from 'moment';

const AddNote: FC<{ orphanId: string; onClose: () => void }> = ({ orphanId, onClose }) => {
  const [addNote] = useMutation(ADD_NOTE);
  const [formData, setFormData] = useState({
    id: '',
    date: '',
    note: '',
    added_by: '',
  });

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = useCallback(() => {
    if (formData.note !== '') {
      addNote({
        variables: {
          newNote: {
            date: moment(formData.date),
            note: formData.note,
            orphanId: orphanId,
          },
        },
      }).then(() => {
        onClose();
      });
    }
  }, [addNote, formData, orphanId, onClose]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingTop: '54px' }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            type="date"
            fullWidth
            label="Date"
            id="date"
            name="date"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={4}
            fullWidth
            label="Note"
            id="note"
            name="note"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="center" direction="row" alignItems="center">
        <Grid item mt={4}>
          <MDButton
            type="submit"
            color="info"
            fontWeight="medium"
            style={{ marginRight: 5 }}
            onClick={onSubmit}>
            Add Note
          </MDButton>
          <MDButton
            type="submit"
            color="error"
            fontWeight="medium"
            style={{ marginRight: 5 }}
            onClick={onSubmit}>
            Cancel
          </MDButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AddNote;
