import { ApolloClient, ApolloLink, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { AUTH_TOKEN } from './connection/ApplicationContext';
import UniversalCookie from 'universal-cookie';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const universalCookie = new UniversalCookie();

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: universalCookie.get(AUTH_TOKEN) || null,
    },
  }));

  return forward(operation);
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BASE_URL });

export const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(
    authMiddleware,
    // httpLink,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createUploadLink({
      uri: process.env.REACT_APP_BASE_URL,
    }),
  ),
});
